import WebMapComponent from '../../mapView';

const token =
  '3NKHt6i2urmWtqOuugvr9Q2DuRflGLU8iu1EBpqW-7GKpsCZrmaJ05_OXTQFCRizOl0g1jXl86yx8fo17XG_O5hbw9uZs-ra_PC3XAvuWoVmZBNP30EXYd9GwPh6mg2n';

const WebMapJSON = {
  operationalLayers: [
    {
      layerType: 'ArcGISTiledMapServiceLayer',
      url: 'https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer',
      id: 'World_Hillshade_3805',
      title: 'World Hillshade',
      opacity: 1,
      minScale: 0,
      maxScale: 0,
      token,
    },
    {
      type: 'VectorTileLayer',
      styleUrl:
        'https://cdn.arcgis.com/sharing/rest/content/items/7dc6cea0b1764a1f9af2e679f642f0f5/resources/styles/root.json',
      id: 'VectorTile_2333',
      title: 'World Topographic Map',
      opacity: 1,
      minScale: 0,
      maxScale: 0,
      token,
    },
    {
      id: '18a709db9cc-layer-0',
      title: 'ZipCodes',
      opacity: 1,
      minScale: 0,
      maxScale: 0,
      url: 'https://services5.arcgis.com/9fQmObndozAJu9f5/arcgis/rest/services/ZipCode/FeatureServer/0',
      layerType: 'ArcGISFeatureLayer',
      layerDefinition: {
        definitionExpression: null,
        drawingInfo: {
          labelingInfo: [
            {
              labelExpression: '[ZIP_CODE]',
              labelExpressionInfo: { expression: '$feature["ZIP_CODE"]' },
              labelPlacement: 'esriServerPolygonPlacementAlwaysHorizontal',
              labelPosition: 'curved',
              maxScale: 0,
              minScale: 344827,
              repeatLabel: true,
              symbol: {
                type: 'esriTS',
                color: [0, 0, 128, 255],
                font: { family: 'Arial', size: 9, weight: 'bold' },
                horizontalAlignment: 'center',
                kerning: true,
                haloColor: [255, 255, 255, 255],
                haloSize: 0.75,
                rightToLeft: false,
                rotated: false,
                text: '',
                verticalAlignment: 'bottom',
                xoffset: 0,
                yoffset: 0,
                angle: 0,
              },
              useCodedValues: true,
            },
          ],
          renderer: {
            type: 'simple',
            description: '',
            label: '',
            symbol: {
              type: 'esriSFS',
              color: null,
              outline: {
                type: 'esriSLS',
                color: [0, 77, 168, 255],
                width: 1.35,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
          },
        },
      },
      showLabels: true,
      token,
    },
    {
      id: '18f3a6a47c2-layer-4',
      title: 'Block Group',
      url: 'https://utility.arcgis.com/usrsvcs/servers/e8884cc26c4f4ed9bb6676b7755f7100/rest/services/USA_Demographics_and_Boundaries_2023/MapServer/12',
      layerType: 'ArcGISFeatureLayer',
      layerDefinition: {
        featureReduction: null,
        definitionExpression: null,
        drawingInfo: {
          renderer: {
            type: 'uniqueValue',
            visualVariables: [
              {
                type: 'sizeInfo',
                valueExpression: '$view.scale',
                stops: [
                  {
                    size: 2,
                    value: 19162,
                  },
                  {
                    size: 1,
                    value: 59881,
                  },
                  {
                    size: 0.5,
                    value: 239526,
                  },
                  {
                    size: 0,
                    value: 479052,
                  },
                ],
                target: 'outline',
              },
            ],
            field1: 'TSEGNAME',
            defaultLabel: 'Other',
            defaultSymbol: {
              type: 'esriSFS',
              color: [170, 170, 170, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            uniqueValueGroups: [
              {
                classes: [
                  {
                    label: 'Southern Satellites',
                    symbol: {
                      type: 'esriSFS',
                      color: [237, 81, 81, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Southern Satellites']],
                  },
                  {
                    label: 'Salt of the Earth',
                    symbol: {
                      type: 'esriSFS',
                      color: [20, 158, 206, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Salt of the Earth']],
                  },
                  {
                    label: 'Green Acres',
                    symbol: {
                      type: 'esriSFS',
                      color: [167, 198, 54, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Green Acres']],
                  },
                  {
                    label: 'Savvy Suburbanites',
                    symbol: {
                      type: 'esriSFS',
                      color: [158, 85, 156, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Savvy Suburbanites']],
                  },
                  {
                    label: 'Heartland Communities',
                    symbol: {
                      type: 'esriSFS',
                      color: [252, 146, 31, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Heartland Communities']],
                  },
                  {
                    label: 'Workday Drive',
                    symbol: {
                      type: 'esriSFS',
                      color: [255, 222, 62, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Workday Drive']],
                  },
                  {
                    label: 'Midlife Constants',
                    symbol: {
                      type: 'esriSFS',
                      color: [247, 137, 216, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Midlife Constants']],
                  },
                  {
                    label: 'Pleasantville',
                    symbol: {
                      type: 'esriSFS',
                      color: [183, 129, 74, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Pleasantville']],
                  },
                  {
                    label: 'Comfortable Empty Nesters',
                    symbol: {
                      type: 'esriSFS',
                      color: [60, 175, 153, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Comfortable Empty Nesters']],
                  },
                  {
                    label: 'Rustbelt Traditions',
                    symbol: {
                      type: 'esriSFS',
                      color: [107, 107, 214, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Rustbelt Traditions']],
                  },
                  {
                    label: 'Traditional Living',
                    symbol: {
                      type: 'esriSFS',
                      color: [181, 71, 121, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Traditional Living']],
                  },
                  {
                    label: 'Middleburg',
                    symbol: {
                      type: 'esriSFS',
                      color: [127, 127, 127, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Middleburg']],
                  },
                  {
                    label: 'Rooted Rural',
                    symbol: {
                      type: 'esriSFS',
                      color: [237, 81, 81, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Rooted Rural']],
                  },
                  {
                    label: 'Small Town Sincerity',
                    symbol: {
                      type: 'esriSFS',
                      color: [20, 158, 206, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Small Town Sincerity']],
                  },
                  {
                    label: 'Old and Newcomers',
                    symbol: {
                      type: 'esriSFS',
                      color: [167, 198, 54, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Old and Newcomers']],
                  },
                  {
                    label: 'In Style',
                    symbol: {
                      type: 'esriSFS',
                      color: [158, 85, 156, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['In Style']],
                  },
                  {
                    label: 'Parks and Rec',
                    symbol: {
                      type: 'esriSFS',
                      color: [252, 146, 31, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Parks and Rec']],
                  },
                  {
                    label: 'Up and Coming Families',
                    symbol: {
                      type: 'esriSFS',
                      color: [255, 222, 62, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Up and Coming Families']],
                  },
                  {
                    label: 'Exurbanites',
                    symbol: {
                      type: 'esriSFS',
                      color: [247, 137, 216, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Exurbanites']],
                  },
                  {
                    label: 'Modest Income Homes',
                    symbol: {
                      type: 'esriSFS',
                      color: [183, 129, 74, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Modest Income Homes']],
                  },
                  {
                    label: 'Top Tier',
                    symbol: {
                      type: 'esriSFS',
                      color: [60, 175, 153, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Top Tier']],
                  },
                  {
                    label: 'Bright Young Professionals',
                    symbol: {
                      type: 'esriSFS',
                      color: [107, 107, 214, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Bright Young Professionals']],
                  },
                  {
                    label: 'Home Improvement',
                    symbol: {
                      type: 'esriSFS',
                      color: [181, 71, 121, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Home Improvement']],
                  },
                  {
                    label: 'Rural Bypasses',
                    symbol: {
                      type: 'esriSFS',
                      color: [127, 127, 127, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Rural Bypasses']],
                  },
                  {
                    label: 'The Great Outdoors',
                    symbol: {
                      type: 'esriSFS',
                      color: [237, 81, 81, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['The Great Outdoors']],
                  },
                  {
                    label: 'Hometown Heritage',
                    symbol: {
                      type: 'esriSFS',
                      color: [20, 158, 206, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Hometown Heritage']],
                  },
                  {
                    label: 'Front Porches',
                    symbol: {
                      type: 'esriSFS',
                      color: [167, 198, 54, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Front Porches']],
                  },
                  {
                    label: 'Professional Pride',
                    symbol: {
                      type: 'esriSFS',
                      color: [158, 85, 156, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Professional Pride']],
                  },
                  {
                    label: 'City Lights',
                    symbol: {
                      type: 'esriSFS',
                      color: [252, 146, 31, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['City Lights']],
                  },
                  {
                    label: 'Urban Edge Families',
                    symbol: {
                      type: 'esriSFS',
                      color: [255, 222, 62, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Urban Edge Families']],
                  },
                  {
                    label: 'Prairie Living',
                    symbol: {
                      type: 'esriSFS',
                      color: [247, 137, 216, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Prairie Living']],
                  },
                  {
                    label: 'Set to Impress',
                    symbol: {
                      type: 'esriSFS',
                      color: [183, 129, 74, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Set to Impress']],
                  },
                  {
                    label: 'Young and Restless',
                    symbol: {
                      type: 'esriSFS',
                      color: [60, 175, 153, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Young and Restless']],
                  },
                  {
                    label: 'Boomburbs',
                    symbol: {
                      type: 'esriSFS',
                      color: [107, 107, 214, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Boomburbs']],
                  },
                  {
                    label: 'Emerald City',
                    symbol: {
                      type: 'esriSFS',
                      color: [181, 71, 121, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Emerald City']],
                  },
                  {
                    label: 'Urban Villages',
                    symbol: {
                      type: 'esriSFS',
                      color: [127, 127, 127, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Urban Villages']],
                  },
                  {
                    label: 'Urban Chic',
                    symbol: {
                      type: 'esriSFS',
                      color: [237, 81, 81, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Urban Chic']],
                  },
                  {
                    label: 'Metro Fusion',
                    symbol: {
                      type: 'esriSFS',
                      color: [20, 158, 206, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Metro Fusion']],
                  },
                  {
                    label: 'Rural Resort Dwellers',
                    symbol: {
                      type: 'esriSFS',
                      color: [167, 198, 54, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Rural Resort Dwellers']],
                  },
                  {
                    label: 'Diverse Convergence',
                    symbol: {
                      type: 'esriSFS',
                      color: [158, 85, 156, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Diverse Convergence']],
                  },
                  {
                    label: 'Forging Opportunity',
                    symbol: {
                      type: 'esriSFS',
                      color: [252, 146, 31, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Forging Opportunity']],
                  },
                  {
                    label: 'Metro Renters',
                    symbol: {
                      type: 'esriSFS',
                      color: [255, 222, 62, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Metro Renters']],
                  },
                  {
                    label: 'Family Foundations',
                    symbol: {
                      type: 'esriSFS',
                      color: [247, 137, 216, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Family Foundations']],
                  },
                  {
                    label: 'Down the Road',
                    symbol: {
                      type: 'esriSFS',
                      color: [183, 129, 74, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Down the Road']],
                  },
                  {
                    label: 'Golden Years',
                    symbol: {
                      type: 'esriSFS',
                      color: [60, 175, 153, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Golden Years']],
                  },
                  {
                    label: 'City Commons',
                    symbol: {
                      type: 'esriSFS',
                      color: [107, 107, 214, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['City Commons']],
                  },
                  {
                    label: 'Enterprising Professionals',
                    symbol: {
                      type: 'esriSFS',
                      color: [181, 71, 121, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Enterprising Professionals']],
                  },
                  {
                    label: 'Retirement Communities',
                    symbol: {
                      type: 'esriSFS',
                      color: [127, 127, 127, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Retirement Communities']],
                  },
                  {
                    label: 'Southwestern Families',
                    symbol: {
                      type: 'esriSFS',
                      color: [237, 81, 81, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Southwestern Families']],
                  },
                  {
                    label: 'Trendsetters',
                    symbol: {
                      type: 'esriSFS',
                      color: [20, 158, 206, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Trendsetters']],
                  },
                  {
                    label: 'City Strivers',
                    symbol: {
                      type: 'esriSFS',
                      color: [167, 198, 54, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['City Strivers']],
                  },
                  {
                    label: 'College Towns',
                    symbol: {
                      type: 'esriSFS',
                      color: [158, 85, 156, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['College Towns']],
                  },
                  {
                    label: 'Family Extensions',
                    symbol: {
                      type: 'esriSFS',
                      color: [252, 146, 31, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Family Extensions']],
                  },
                  {
                    label: 'Senior Escapes',
                    symbol: {
                      type: 'esriSFS',
                      color: [255, 222, 62, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Senior Escapes']],
                  },
                  {
                    label: 'Laptops and Lattes',
                    symbol: {
                      type: 'esriSFS',
                      color: [247, 137, 216, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Laptops and Lattes']],
                  },
                  {
                    label: 'NeWest Residents',
                    symbol: {
                      type: 'esriSFS',
                      color: [183, 129, 74, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['NeWest Residents']],
                  },
                  {
                    label: 'Economic BedRock',
                    symbol: {
                      type: 'esriSFS',
                      color: [60, 175, 153, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Economic BedRock']],
                  },
                  {
                    label: 'Social Security Set',
                    symbol: {
                      type: 'esriSFS',
                      color: [107, 107, 214, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Social Security Set']],
                  },
                  {
                    label: 'Pacific Heights',
                    symbol: {
                      type: 'esriSFS',
                      color: [181, 71, 121, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Pacific Heights']],
                  },
                  {
                    label: 'Fresh Ambitions',
                    symbol: {
                      type: 'esriSFS',
                      color: [127, 127, 127, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Fresh Ambitions']],
                  },
                  {
                    label: 'Unclassified',
                    symbol: {
                      type: 'esriSFS',
                      color: [237, 81, 81, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Unclassified']],
                  },
                  {
                    label: 'Silver & Gold',
                    symbol: {
                      type: 'esriSFS',
                      color: [20, 158, 206, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Silver & Gold']],
                  },
                  {
                    label: 'Downtown Melting Pot',
                    symbol: {
                      type: 'esriSFS',
                      color: [167, 198, 54, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Downtown Melting Pot']],
                  },
                  {
                    label: 'Dorms to Diplomas',
                    symbol: {
                      type: 'esriSFS',
                      color: [158, 85, 156, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Dorms to Diplomas']],
                  },
                  {
                    label: 'The Elders',
                    symbol: {
                      type: 'esriSFS',
                      color: [252, 146, 31, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['The Elders']],
                  },
                  {
                    label: 'High Rise Renters',
                    symbol: {
                      type: 'esriSFS',
                      color: [255, 222, 62, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['High Rise Renters']],
                  },
                  {
                    label: 'Farm to Table',
                    symbol: {
                      type: 'esriSFS',
                      color: [247, 137, 216, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Farm to Table']],
                  },
                  {
                    label: 'Military Proximity',
                    symbol: {
                      type: 'esriSFS',
                      color: [183, 129, 74, 255],
                      outline: {
                        type: 'esriSLS',
                        color: [153, 153, 153, 64],
                        width: 0.75,
                        style: 'esriSLSSolid',
                      },
                      style: 'esriSFSSolid',
                    },
                    values: [['Military Proximity']],
                  },
                ],
              },
            ],
            uniqueValueInfos: [
              {
                label: 'Southern Satellites',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Southern Satellites',
              },
              {
                label: 'Salt of the Earth',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Salt of the Earth',
              },
              {
                label: 'Green Acres',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Green Acres',
              },
              {
                label: 'Savvy Suburbanites',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Savvy Suburbanites',
              },
              {
                label: 'Heartland Communities',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Heartland Communities',
              },
              {
                label: 'Workday Drive',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Workday Drive',
              },
              {
                label: 'Midlife Constants',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Midlife Constants',
              },
              {
                label: 'Pleasantville',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Pleasantville',
              },
              {
                label: 'Comfortable Empty Nesters',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Comfortable Empty Nesters',
              },
              {
                label: 'Rustbelt Traditions',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Rustbelt Traditions',
              },
              {
                label: 'Traditional Living',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Traditional Living',
              },
              {
                label: 'Middleburg',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Middleburg',
              },
              {
                label: 'Rooted Rural',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Rooted Rural',
              },
              {
                label: 'Small Town Sincerity',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Small Town Sincerity',
              },
              {
                label: 'Old and Newcomers',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Old and Newcomers',
              },
              {
                label: 'In Style',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'In Style',
              },
              {
                label: 'Parks and Rec',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Parks and Rec',
              },
              {
                label: 'Up and Coming Families',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Up and Coming Families',
              },
              {
                label: 'Exurbanites',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Exurbanites',
              },
              {
                label: 'Modest Income Homes',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Modest Income Homes',
              },
              {
                label: 'Top Tier',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Top Tier',
              },
              {
                label: 'Bright Young Professionals',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Bright Young Professionals',
              },
              {
                label: 'Home Improvement',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Home Improvement',
              },
              {
                label: 'Rural Bypasses',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Rural Bypasses',
              },
              {
                label: 'The Great Outdoors',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'The Great Outdoors',
              },
              {
                label: 'Hometown Heritage',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Hometown Heritage',
              },
              {
                label: 'Front Porches',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Front Porches',
              },
              {
                label: 'Professional Pride',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Professional Pride',
              },
              {
                label: 'City Lights',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'City Lights',
              },
              {
                label: 'Urban Edge Families',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Urban Edge Families',
              },
              {
                label: 'Prairie Living',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Prairie Living',
              },
              {
                label: 'Set to Impress',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Set to Impress',
              },
              {
                label: 'Young and Restless',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Young and Restless',
              },
              {
                label: 'Boomburbs',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Boomburbs',
              },
              {
                label: 'Emerald City',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Emerald City',
              },
              {
                label: 'Urban Villages',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Urban Villages',
              },
              {
                label: 'Urban Chic',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Urban Chic',
              },
              {
                label: 'Metro Fusion',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Metro Fusion',
              },
              {
                label: 'Rural Resort Dwellers',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Rural Resort Dwellers',
              },
              {
                label: 'Diverse Convergence',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Diverse Convergence',
              },
              {
                label: 'Forging Opportunity',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Forging Opportunity',
              },
              {
                label: 'Metro Renters',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Metro Renters',
              },
              {
                label: 'Family Foundations',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Family Foundations',
              },
              {
                label: 'Down the Road',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Down the Road',
              },
              {
                label: 'Golden Years',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Golden Years',
              },
              {
                label: 'City Commons',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'City Commons',
              },
              {
                label: 'Enterprising Professionals',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Enterprising Professionals',
              },
              {
                label: 'Retirement Communities',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Retirement Communities',
              },
              {
                label: 'Southwestern Families',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Southwestern Families',
              },
              {
                label: 'Trendsetters',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Trendsetters',
              },
              {
                label: 'City Strivers',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'City Strivers',
              },
              {
                label: 'College Towns',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'College Towns',
              },
              {
                label: 'Family Extensions',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Family Extensions',
              },
              {
                label: 'Senior Escapes',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Senior Escapes',
              },
              {
                label: 'Laptops and Lattes',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Laptops and Lattes',
              },
              {
                label: 'NeWest Residents',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'NeWest Residents',
              },
              {
                label: 'Economic BedRock',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Economic BedRock',
              },
              {
                label: 'Social Security Set',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Social Security Set',
              },
              {
                label: 'Pacific Heights',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Pacific Heights',
              },
              {
                label: 'Fresh Ambitions',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Fresh Ambitions',
              },
              {
                label: 'Unclassified',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Unclassified',
              },
              {
                label: 'Silver & Gold',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Silver & Gold',
              },
              {
                label: 'Downtown Melting Pot',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Downtown Melting Pot',
              },
              {
                label: 'Dorms to Diplomas',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Dorms to Diplomas',
              },
              {
                label: 'The Elders',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'The Elders',
              },
              {
                label: 'High Rise Renters',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'High Rise Renters',
              },
              {
                label: 'Farm to Table',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Farm to Table',
              },
              {
                label: 'Military Proximity',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                value: 'Military Proximity',
              },
            ],
          },
        },
      },
      showLabels: true,
      opacity: 0.72,
      minScale: 160000,
      maxScale: 0,
      token,
    },
    {
      id: 'markerLayer',
      title: 'Marker Layer',
      layerType: 'GraphicsLayer',
      opacity: 1,
      minScale: 0,
      maxScale: 0,
      featureCollection: {
        layers: [
          {
            layerDefinition: {
              geometryType: 'esriGeometryPoint',
              objectIdField: 'ObjectID',
              drawingInfo: {
                renderer: {
                  type: 'simple',
                  symbol: {
                    type: 'esriPMS',
                    url: 'https://appvestmap.netlify.app/marker%20center.png',
                    width: 64,
                    height: 64,
                  },
                },
              },
              fields: [
                {
                  name: 'ObjectID',
                  type: 'esriFieldTypeOID',
                  alias: 'ObjectID',
                },
              ],
            },
            featureSet: {
              features: [
                {
                  attributes: { ObjectID: 1 },
                  geometry: {
                    x: -72.935036017064,
                    y: 41.302848016519,
                    spatialReference: { wkid: 4326, latestWkid: 4326 },
                  },
                },
              ],
              geometryType: 'esriGeometryPoint',
            },
          },
        ],
      },
    },
  ],
  mapOptions: {
    extent: {
      spatialReference: { wkid: 4326, latestWkid: 4326 },
      xmin: -97.936036017064,
      ymin: 28.801848016519003,
      xmax: -47.936036017064,
      ymax: 53.801848016519,
    },
    spatialReference: { wkid: 4326, latestWkid: 4326 },
    showAttribution: true,
    scale: 50000.819286,
  },
  spatialReference: { wkid: 4326, latestWkid: 4326 },
  exportOptions: { dpi: 100, outputSize: [1120, 560] },
  layoutOptions: { scaleBarOptions: {} },
};
const Testing = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <WebMapComponent />
    </div>
  );
};

export default Testing;
