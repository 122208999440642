import { useEffect, useState, useContext } from 'react';

import { Box, CircularProgress, Typography, Divider } from '@mui/material';

// TOP BOX ICONS
import { ReactComponent as GeneratePDFIconLight } from '@/../../public/pdfIconLight.svg';
import { ReactComponent as GenerateProReportIconLight } from '@/../../public/proReportIconLight.svg';

// BOTTOM BOX ICONS
import { ReactComponent as DemoIconLight } from '@/../../public/demoIconLight.svg';
import { ReactComponent as DemoIconDark } from '@/../../public/demoIconDark.svg';

import { ReactComponent as IncomeIconLight } from '@/../../public/incomeIconLight.svg';
import { ReactComponent as IncomeIconDark } from '@/../../public/incomeIconDark.svg';

import { ReactComponent as HPIIconLight } from '@/../../public/hpiIconLight.svg';
import { ReactComponent as HPIIconDark } from '@/../../public/hpiIconDark.svg';

import { ReactComponent as SchoolsIconLight } from '@/../../public/schoolIconLight.svg';
import { ReactComponent as SchoolsIconDark } from '@/../../public/schoolIconDark.svg';

import { ReactComponent as CrimeIconLight } from '@/../../public/crimeIconLight.svg';
import { ReactComponent as CrimeIconDark } from '@/../../public/crimeIconDark.svg';

import { ReactComponent as ExpansionIconLight } from '@/../../public/expansionIconLight.svg';
import { ReactComponent as ExpansionIconDark } from '@/../../public/expansionIconDark.svg';

import { ReactComponent as RentIconLight } from '@/../../public/rentIconLight.svg';
import { ReactComponent as RentIconDark } from '@/../../public/rentIconDark.svg';

import { ReactComponent as NeighborhoodIconLight } from '@/../../public/neighborhoodIconLight.svg';
import { ReactComponent as NeighborhoodIconDark } from '@/../../public/neighborhoodIconDark.svg';

import { ReactComponent as MSAIconLight } from '@/../../public/msaIconLight.svg';
import { ReactComponent as MSAIconDark } from '@/../../public/msaIconDark.svg';

import { ReactComponent as AiAnalysisDark } from '@/../../public/flaskDark.svg';
import { ReactComponent as AiAnalysisLight } from '@/../../public/flaskLight.svg';

import VestmapContext from '../../contexts/VestmapContext';
import CustomVestmapContext from '../../contexts/CustomVestmapContext';

function Sidebar({
  pdfStatus,
  proReportStatus,
  generatePDFFunc,
  generateProFunc,
  viewPDFFunc,
  vestmap,
  viewProFunc,
  setCurrLocation,
  currLocation,
}) {
  const [selected, setSelected] = useState(() => {
    if (vestmap?.ai_analysis) return 'ai_analysis';
    return 'demographic_group';
  });
  const [pdfLoader, setPdfLoader] = useState(false);
  const [proLoader, setProLoader] = useState(false);

  const {
    analysisCompletionFlag,
    analysisLoader,
    setAnalysisCompletionFlag,
    setAnalysisLoader,
    analysisError,
    setAnalysisError,
    setVestmap,
    setFetchFromSidebar,
  } = useContext(CustomVestmapContext);

  useEffect(() => {
    if (currLocation === '' || currLocation === null) return;
    if (currLocation !== selected) {
      setSelected(currLocation);
    }
  }, [currLocation]);

  const styles = {
    selected: {
      backgroundColor: '#39585C',
      color: '#FFFFFF',
    },

    unselected: {
      color: '#39585C',
      fontWeight: 400,
      fontStyle: 'normal',
    },

    itemBoxStyle: {
      display: 'flex',
      height: '1.1rem',
      padding: '1rem',
      alignItems: 'center',
      gap: '0.75rem',
      alignSelf: 'stretch',
      borderRadius: '0.75rem',
    },

    itemTextStyle: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        xs: '0.875rem',
        sm: '.75rem',
        lg: '0.875rem',
      },
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '150%',
      letterSpacing: '0.0125rem',
    },

    upperBoxInnerStyle: {
      boxSizing: 'border-box',
      backgroundColor: '#4FA490',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.75rem',
      borderRadius: '1rem',
      padding: '0.75rem 0.2rem 0.75rem 0.2rem',
      cursor: 'pointer',
    },

    upperBoxTypography: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        sm: '16px',
        xs: '0.8rem',
      },
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.2px',
      textAlign: 'center',
      color: '#FFFFFF',
    },

    selectedBox: {
      backgroundColor: '#39585C',
    },
    selectedText: {
      color: '#FFFFFF',
      fontWeight: 800,
      textAlign: 'left',

      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedText: {
      color: '#39585C',
      fontWeight: 400,
      textAlign: 'left',
      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedBox: {
      backgroundColor: '#F9F9F9',
    },
    iconStyle: {
      width: '1.5rem',
      height: '1.5rem',
    },
    lowerBoxInnerStyle: {
      boxSizing: 'border-box',

      backgroundColor: '#4FA490',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      borderRadius: '1.2rem',
      padding: '16px',

      cursor: 'pointer',
    },
  };

  useEffect(() => {
    if (pdfStatus !== undefined) {
      setPdfLoader(false);
    }
    if (proReportStatus !== undefined) {
      setProLoader(false);
    }
  }, [pdfStatus, proReportStatus]);

  async function handleGeneratePDF() {
    try {
      setPdfLoader(true);
      await generatePDFFunc();
      setPdfLoader(false);
    } catch (error) {
      console.log(error);
      setPdfLoader(false);
    }
  }

  async function handleGenerateProReport() {
    try {
      setProLoader(true);
      await generateProFunc();
      setProLoader(false);
    } catch (error) {
      console.log(error);
      setProLoader(false);
    }
  }

  function setSelectedGroup(group) {
    setSelected(group);
    setCurrLocation(group);
  }

  useEffect(() => {
    if (analysisError) {
      setAnalysisLoader(false);
      setAnalysisError(false);
      setSelectedGroup('demographic_group');
    }
  }, [analysisError]);

  function UpperBox() {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.8rem',
            width: '96%',
          }}
        >
          {
            <Box
              sx={styles.upperBoxInnerStyle}
              onClick={() => {
                // remove the ai_analysis from the vestmap
                const newVestmap = { ...vestmap };
                delete newVestmap.ai_analysis;
                setVestmap(newVestmap);
                setFetchFromSidebar(true);
                setSelectedGroup('ai_analysis');
                setAnalysisLoader(true);
              }}
            >
              {analysisLoader ? (
                <CircularProgress
                  size={'1.5rem'}
                  thickness={4.6}
                  style={{ color: '#fff' }}
                />
              ) : (
                <AiAnalysisLight style={styles.iconStyle} />
              )}
              <Typography sx={styles.upperBoxTypography}>
                Generate AI Analysis
              </Typography>
            </Box>
          }

          <Box
            sx={styles.upperBoxInnerStyle}
            onClick={() => {
              if (pdfStatus === undefined) {
                handleGeneratePDF();
              } else {
                viewPDFFunc();
              }
            }}
          >
            {pdfLoader ? (
              <CircularProgress
                size={'1.5rem'}
                thickness={4.6}
                style={{ color: '#fff' }}
              />
            ) : (
              <GeneratePDFIconLight />
            )}
            <Typography sx={styles.upperBoxTypography}>
              {pdfStatus === undefined ? 'Generate PDF' : 'View PDF'}
            </Typography>
          </Box>
          <Box
            sx={styles.upperBoxInnerStyle}
            onClick={() => {
              if (proReportStatus === undefined) {
                handleGenerateProReport();
              } else {
                viewProFunc();
              }
            }}
          >
            {proLoader ? (
              <CircularProgress
                size={'1.5rem'}
                thickness={4.6}
                style={{ color: '#fff' }}
              />
            ) : (
              <GenerateProReportIconLight />
            )}
            <Typography sx={styles.upperBoxTypography}>
              {proReportStatus === undefined
                ? 'Generate Pro Report'
                : 'View Pro Report'}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: '90%',
            border: '1px solid #E2E8F0',
            margin: '1.5rem 0 3rem 0',
          }}
        />
      </>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '13.3rem',

        alignItems: 'center',
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem',
      }}
    >
      {/* UPPER  BOX*/}

      {vestmap?.templateName ? (
        vestmap.templateName === 'Default (DISCERN)' && <UpperBox />
      ) : (
        <UpperBox />
      )}

      {/* More Green Box Group */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '96%',
          position: 'sticky',
          top: '0',
        }}
      >
        {
          // This will be shown only if the AI analysis is generated completely
          analysisCompletionFlag && (
            <Box
              sx={[
                styles.lowerBoxInnerStyle,
                selected === 'ai_analysis'
                  ? styles.selectedBox
                  : styles.unselectedBox,
              ]}
              onClick={setSelectedGroup.bind(this, 'ai_analysis')}
            >
              {selected === 'ai_analysis' ? (
                <AiAnalysisLight style={styles.iconStyle} />
              ) : (
                <AiAnalysisDark style={styles.iconStyle} />
              )}
              <Typography
                sx={
                  selected === 'ai_analysis'
                    ? styles.selectedText
                    : styles.unselectedText
                }
              >
                Generate AI Analysis
              </Typography>
            </Box>
          )
        }

        {vestmap?.demographic_group && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'demographic_group'
                ? styles.selectedBox
                : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'demographic_group')}
          >
            {selected === 'demographic_group' ? (
              <DemoIconLight style={styles.iconStyle} />
            ) : (
              <DemoIconDark style={styles.iconStyle} />
            )}
            <Typography
              sx={
                selected === 'demographic_group'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Demographic Group
            </Typography>
          </Box>
        )}

        {vestmap?.income_block_tract && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'income_block_tract'
                ? styles.selectedBox
                : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'income_block_tract')}
          >
            {selected === 'income_block_tract' ? (
              <IncomeIconLight style={styles.iconStyle} />
            ) : (
              <IncomeIconDark style={styles.iconStyle} />
            )}
            <Typography
              sx={
                selected === 'income_block_tract'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Income
            </Typography>
          </Box>
        )}

        {(vestmap?.hpi_tracts ||
          vestmap?.hpi_zips ||
          vestmap?.hpi_counties) && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'HPI_special'
                ? styles.selectedBox
                : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'HPI_special')}
          >
            {selected === 'HPI_special' ? (
              <HPIIconLight style={styles.iconStyle} />
            ) : (
              <HPIIconDark style={styles.iconStyle} />
            )}
            <Typography
              sx={
                selected === 'HPI_special'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              House Price Index
            </Typography>
          </Box>
        )}

        {vestmap?.schools && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'schools'
                ? styles.selectedBox
                : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'schools')}
          >
            {selected === 'schools' ? (
              <SchoolsIconLight style={styles.iconStyle} />
            ) : (
              <SchoolsIconDark style={styles.iconStyle} />
            )}
            <Typography
              sx={
                selected === 'schools'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Schools
            </Typography>
          </Box>
        )}

        {vestmap?.crime && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'crime' ? styles.selectedBox : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'crime')}
          >
            {selected === 'crime' ? (
              <CrimeIconLight style={styles.iconStyle} />
            ) : (
              <CrimeIconDark style={styles.iconStyle} />
            )}
            <Typography
              sx={
                selected === 'crime'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Crime
            </Typography>
          </Box>
        )}

        {vestmap?.expansion && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'expansion'
                ? styles.selectedBox
                : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'expansion')}
          >
            {selected === 'expansion' ? (
              <ExpansionIconLight style={styles.iconStyle} />
            ) : (
              <ExpansionIconDark style={styles.iconStyle} />
            )}
            <Typography
              sx={
                selected === 'expansion'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Expansion
            </Typography>
          </Box>
        )}

        {vestmap?.rent && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'rent' ? styles.selectedBox : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'rent')}
          >
            {selected === 'rent' ? (
              <RentIconLight style={styles.iconStyle} />
            ) : (
              <RentIconDark style={styles.iconStyle} />
            )}

            <Typography
              sx={
                selected === 'rent'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Rent
            </Typography>
          </Box>
        )}

        {vestmap?.neighborhood && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'neighbourhood'
                ? styles.selectedBox
                : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(this, 'neighbourhood')}
          >
            {selected === 'neighbourhood' ? (
              <NeighborhoodIconLight style={styles.iconStyle} />
            ) : (
              <NeighborhoodIconDark style={styles.iconStyle} />
            )}

            <Typography
              sx={
                selected === 'neighbourhood'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Neighborhood
            </Typography>
          </Box>
        )}

        {vestmap?.metropolitan_statistical_area && (
          <Box
            sx={[
              styles.lowerBoxInnerStyle,
              selected === 'metropolitan_statistical_area'
                ? styles.selectedBox
                : styles.unselectedBox,
            ]}
            onClick={setSelectedGroup.bind(
              this,
              'metropolitan_statistical_area',
            )}
          >
            {selected === 'metropolitan_statistical_area' ? (
              <MSAIconLight style={styles.iconStyle} />
            ) : (
              <MSAIconDark style={styles.iconStyle} />
            )}
            <Typography
              sx={
                selected === 'metropolitan_statistical_area'
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              Metropolitan Statistical Area
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Sidebar;
