/*
List of Maps:
1. Income
2. Neighborhood
3. Schools
4. Crime
5. HPI
6. Demographic
7. MSA
8. Expansion
*/

import axios from 'axios';
import qs from 'qs';

const TIMEOUT = 5000;

function calculateTime(startTime, endTime) {
  const timeDiff = endTime - startTime;
  const seconds = timeDiff / 1000;
  return seconds;
}

/**
 * Checks if a given URL contains the substring "moha5225", indicating a specific error.
 *
 * @param {string} url - The URL to be checked.
 * @returns {boolean} - Returns true if the URL contains "moha5225", otherwise false.
 */
function checkMoha5225error(url) {
  if (url.includes('moha5225')) {
    return true;
  }
  return false;
}

// Get all other stuff required for the WebMap as JSON
const getRestData = vestmap => {
  const mapExtent = vestmap.addressInfo || vestmap.address_info;

  const output = {
    mapOptions: {
      extent: {
        spatialReference: mapExtent.spatialReference,
        xmin: mapExtent.candidates[0].extent.xmin,
        ymin: mapExtent.candidates[0].extent.ymin,
        xmax: mapExtent.candidates[0].extent.xmax,
        ymax: mapExtent.candidates[0].extent.ymax,
      },
      spatialReference: mapExtent.spatialReference,
      showAttribution: true,
      scale: 50000.819286,
    },
    spatialReference: mapExtent.spatialReference,
    exportOptions: { dpi: 100, outputSize: [1120, 560] },
    layoutOptions: { scaleBarOptions: {} },
  };

  return output;
};

const markerLayer = mapExtent => {
  const output = {
    id: 'markerLayer',
    title: 'Marker Layer',
    layerType: 'GraphicsLayer',
    opacity: 1,
    minScale: 0,
    maxScale: 0,
    featureCollection: {
      layers: [
        {
          layerDefinition: {
            geometryType: 'esriGeometryPoint',
            objectIdField: 'ObjectID',
            drawingInfo: {
              renderer: {
                type: 'simple',
                symbol: {
                  type: 'esriPMS',
                  url: 'https://appvestmap.netlify.app/marker%20center.png',
                  width: 64,
                  height: 64,
                },
              },
            },
            fields: [
              {
                name: 'ObjectID',
                type: 'esriFieldTypeOID',
                alias: 'ObjectID',
              },
            ],
          },
          featureSet: {
            features: [
              {
                attributes: {
                  ObjectID: 1,
                },
                geometry: {
                  x: mapExtent.candidates[0].location.x,
                  y: mapExtent.candidates[0].location.y,
                  spatialReference: mapExtent.spatialReference,
                },
              },
            ],
            geometryType: 'esriGeometryPoint',
          },
        },
      ],
    },
  };
  return output;
};

async function getWebmapLayers(webmapID, token) {
  try {
    validateInputs(webmapID, token);

    const url = `https://www.arcgis.com/sharing/rest/content/items/${webmapID}/data?f=pjson&token=${token}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();

    if (data.operationalLayers) {
      data.operationalLayers = addTokenToOperationalLayers(
        data.operationalLayers,
        token,
      );
    }

    if (data.baseMap && data.baseMap.baseMapLayers) {
      data.baseMap = addTokenToBaseMapLayers(data.baseMap, token);
    }

    return data;
  } catch (error) {
    console.error('Error fetching webmap layers:', error);
    throw error;
  }
}

function validateInputs(webmapID, token) {
  if (!webmapID) {
    throw new Error('webmapID is null or undefined');
  }
  if (!token) {
    throw new Error('token is null or undefined');
  }
}

function addTokenToOperationalLayers(layers, token) {
  return layers.map(layer => {
    const updatedLayer = { ...layer, token };
    if (layer.layers) {
      updatedLayer.layers = layer.layers.map(subLayer => ({
        ...subLayer,
        token,
      }));
    }
    return updatedLayer;
  });
}

function addTokenToBaseMapLayers(baseMap, token) {
  baseMap.token = token;
  baseMap.baseMapLayers = baseMap.baseMapLayers.map(baseMapLayer => {
    if (baseMapLayer.title === 'World Topographic Map') {
      return getWorkingVectorTileLayer(token);
    } else {
      return { ...baseMapLayer, token };
    }
  });
  return baseMap;
}

function getWorkingVectorTileLayer(token) {
  return {
    type: 'VectorTileLayer',
    styleUrl:
      'https://cdn.arcgis.com/sharing/rest/content/items/7dc6cea0b1764a1f9af2e679f642f0f5/resources/styles/root.json',
    id: 'VectorTile_2333',
    title: 'World Topographic Map',
    opacity: 1,
    minScale: 0,
    maxScale: 0,
    token,
  };
}

async function getWebMapJSON(webmapID, vestmap, token) {
  try {
    const mapExtent = vestmap.addressInfo || vestmap.address_info;

    let layers = await getWebmapLayers(webmapID, token);
    layers.operationalLayers.push(markerLayer(mapExtent));

    const WebMapJSON = {
      operationalLayers: layers.operationalLayers,
      baseMap: layers.baseMap,
      ...getRestData(vestmap),
    };

    return WebMapJSON;
  } catch (error) {
    console.error(error);
    throw new Error('Error in creating WebMapJSON');
  }
}

/**
 * Fetches a map from ArcGIS based on the provided map name, vestmap data, and token.
 *
 * @param {string} mapName - The name of the map to fetch. Must be one of the predefined map names. Available:
 * - 'income_map'
 * - 'neighborhood_map'
 * - 'schools_map'
 * - 'crime_map'
 * - 'hpi_map'
 * - 'demographics_map'
 * - 'msa_map'
 * - 'expansion_map'
 * @param {Object} vestmap - The vestmap data containing address information and other relevant details.
 * @param {string} token - The authentication token required for accessing ArcGIS services.
 * @param {boolean} [devMode=false] - Optional flag to enable development mode, which affects retry behavior.
 * @returns {Promise<string>} - A promise that resolves to the URL of the fetched map image.
 * @throws {Error} - Throws an error if the map name is invalid, or if the maximum number of retries is reached.
 */
const fetchMapFromArcGIS = async (
  mapName,
  vestmap,
  token,
  devMode = false,
  webmapID = null,
) => {
  const startTime = new Date().getTime();

  const webmapIDs = {
    income_map: '716041d8fa6345b6b59a24f38ad9f54b',
    neighborhood_map: 'a51ea46750874e768e6c49b06a717642',
    schools_map: '655b897aece849c19770e7637a018bd8',
    crime_map: 'b43167d1c89140a1b9e0d1566da3d8f1',
    hpi_map: 'fff2d74039b84c16836bd311b66beeac',
    demographics_map: 'eec677cb49704c48b79c5e8af2214409',
    msa_map: 'TODO',
    expansion_map: '8b1cb62cfb8e43c3874016694043f024',
  };

  let WebMapJSON = null;
  if (webmapIDs[mapName]) {
    WebMapJSON = await getWebMapJSON(webmapIDs[mapName], vestmap, token);
  } else {
    if (!webmapID) {
      throw new Error('mapName not found and wemapID not provided');
    }

    WebMapJSON = await getWebMapJSON(webmapID, vestmap, token);
  }

  console.log(WebMapJSON);

  const data = qs.stringify({
    Web_Map_as_JSON: JSON.stringify(WebMapJSON),
    Format: 'JPG',
    f: 'json',
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task/execute/execute',
    headers: {},
    data: data,
  };

  let status = false;
  let retries = 0;
  let mohaErrorFlag = false;
  while (!status && retries < 3) {
    try {
      console.log(`[${mapName} Map] Trying...`);
      const response = await axios.request(config);
      const result = response?.data?.results || null;

      if (!result) {
        console.log('[Null Result Error]');
        console.log({ [mapName]: response.data, status: response.status });
        throw new Error(`Error while getting ${mapName.toLowerCase()} map`);
      }
      const parsedRes = result[0].value?.url || null;
      if (checkMoha5225error(parsedRes)) {
        console.log('[checkMoha5225 error]');
        mohaErrorFlag = true;
        throw new Error(`Error while getting ${mapName.toLowerCase()} map`);
      }

      status = true;
      const endTime = new Date().getTime();
      console.log(
        `[${mapName} Map] Success `,
        calculateTime(startTime, endTime),
        'seconds',
      );

      return parsedRes;
    } catch (error) {
      console.log(`[${mapName} Map] Error. Retrying...`);
      console.log({ error });

      if (!devMode && retries === 2) {
        throw new Error(`[${mapName} Map] Max retries reached`);
      }
      if (!mohaErrorFlag) {
        console.log('[TIMEOUT SET]');
        await new Promise(resolve => setTimeout(resolve, TIMEOUT));
      }

      mohaErrorFlag = false;
      retries++;
    }
  }
};

const getMap_MSA = async (vestmap, token, devMode = false) => {
  const startTime = new Date().getTime();
  const mapExtent = vestmap.addressInfo || vestmap.address_info;

  const x = mapExtent.candidates[0].extent.xmin;
  const y = mapExtent.candidates[0].extent.ymin;

  const width = 1000,
    height = 500;

  const xmin = x - width / 2;
  const xmax = x + width / 2;
  const ymin = y - height / 2;
  const ymax = y + height / 2;

  // This map is of a different nature so I'm hardcoding the WebMapJSON here
  const WebMapJSON = {
    operationalLayers: [
      {
        layerType: 'ArcGISTiledMapServiceLayer',
        url: 'https: //services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer',
        id: 'World_Hillshade_3805',
        title: 'World Hillshade',
        opacity: 1,
        minScale: 0,
        maxScale: 0,
      },
      {
        type: 'VectorTileLayer',
        styleUrl:
          'https://cdn.arcgis.com/sharing/rest/content/items/7dc6cea0b1764a1f9af2e679f642f0f5/resources/styles/root.json',
        id: 'VectorTile_2333',
        title: 'World Topographic Map',
        opacity: 1,
        minScale: 0,
        maxScale: 0,
      },
      {
        token,
        id: 'MSA_Enrich_Update_2023',
        title: 'MSA_Enrich_Update_2023',
        opacity: 0.55,
        minScale: 0,
        maxScale: 0,
        url: 'https://services5.arcgis.com/9fQmObndozAJu9f5/arcgis/rest/services/MSA_Enrich_Update_2023/FeatureServer/0',
        layerType: 'ArcGISFeatureLayer',
        layerDefinition: {
          definitionExpression: null,
          drawingInfo: {
            renderer: {
              type: 'simple',
              visualVariables: [
                {
                  type: 'sizeInfo',
                  valueExpression: '$view.scale',
                  stops: [
                    {
                      size: 1.5,
                      value: 1868219,
                    },
                    {
                      size: 0.75,
                      value: 5838185,
                    },
                    {
                      size: 0.375,
                      value: 23352739,
                    },
                    {
                      size: 0,
                      value: 46705477,
                    },
                  ],
                  target: 'outline',
                },
              ],
              symbol: {
                type: 'esriSFS',
                color: [227, 139, 79, 255],
                outline: {
                  type: 'esriSLS',
                  color: [255, 255, 255, 64],
                  width: 0.75,
                  style: 'esriSLSSolid',
                },
                style: 'esriSFSSolid',
              },
            },
          },
        },
        showLabels: true,
      },
      markerLayer(mapExtent),
    ],
    mapOptions: {
      extent: {
        spatialReference: mapExtent.spatialReference,
        xmin,
        ymin,
        xmax,
        ymax,
      },
      spatialReference: mapExtent.spatialReference,
      showAttribution: true,
      scale: 4622324.434309,
    },
    // exportOptions: {
    //   dpi: 72,
    //   outputSize: [800, 300],
    // },
    exportOptions: { dpi: 100, outputSize: [1120, 560] },
    layoutOptions: {
      scaleBarOptions: {},
    },
  };

  const data = qs.stringify({
    Web_Map_as_JSON: JSON.stringify(WebMapJSON),
    Format: 'JPG',
    f: 'json',
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task/execute/execute',
    headers: {},
    data: data,
  };

  let status = false;
  let retries = 0;
  let mohaErrorFlag = false;
  while (!status && retries < 3) {
    try {
      console.log('[MSA Map] Trying...');
      const response = await axios.request(config);
      const result = response?.data?.results || null;

      if (!result) {
        console.log('[Null Result Error]');
        console.log({ MSA: response.data, status: response.status });
        throw new Error('Error while getting MSA map');
      }
      const parsedRes = result[0].value?.url || null;
      if (checkMoha5225error(parsedRes)) {
        console.log('[checkMoha5225 error]');
        mohaErrorFlag = true;
        throw new Error('Error while getting msa map');
      }

      status = true;
      const endTime = new Date().getTime();
      console.log(
        '[MSA Map] Success ',
        calculateTime(startTime, endTime),
        'seconds',
      );

      return parsedRes;
    } catch (error) {
      console.log('[MSA Map] Error. Retrying...');
      console.log({ error });

      if (!devMode && retries === 2) {
        throw new Error('[MSA Map] Max retries reached');
      }
      if (!mohaErrorFlag) {
        console.log('[TIMEOUT SET]');
        await new Promise(resolve => setTimeout(resolve, TIMEOUT));
      }

      mohaErrorFlag = false;
      retries++;
    }
  }
};

/**
 * Stores the generated map link in the database.
 *
 * @param {string} vestmapID - The ID of the vestmap.
 * @param {string} mapName - The name of the map section. Available map section names are:
 * - 'income_map'
 * - 'neighborhood_map'
 * - 'schools_map'
 * - 'crime_map'
 * - 'hpi_map'
 * - 'demographics_map'
 * - 'msa_map'
 * - 'expansion_map'
 * @param {string} mapLink - The temporary arcgis URL of the generated map.
 * @returns {Promise<string|null>} - The URL of the stored map or null if the storage fails.
 * @throws {Error} - Throws an error if any of the parameters are null or if the storage fails.
 */
async function storeMap(vestmapID, mapName, mapLink) {
  try {
    if (!mapLink) {
      throw new Error('mapLink is null');
    }
    if (!mapName) {
      throw new Error('sectionName is null');
    }
    if (!vestmapID) {
      throw new Error('vestmapID is null');
    }

    const url = process.env.REACT_APP_NODE_URL + '/map/' + vestmapID;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sectionName: mapName,
        mapLink,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to store map');
    }
    const data = await response.json();
    if (!data.imageLink) {
      throw new Error('Failed to retrieve imageLink');
    }

    return data.imageLink;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Generates a map based on the specified section name.
 *
 * @param {string} mapName - The name of the map which is to be generated.
 * Hardcoded section names are:
 * - 'income_map'
 * - 'neighborhood_map'
 * - 'schools_map'
 * - 'crime_map'
 * - 'hpi_map'
 * - 'demographics_map'
 * - 'msa_map'
 * - 'expansion_map'
 * @param {object} vestmap - The vestmap object containing address information.
 * @param {string} token - The arcgis access token.
 * @param {boolean} [devMode=false] - Flag to indicate if the function is running in development mode.
 * @returns {Promise<[String,String]|null>} - An array containing the map link and the map name.
 * @throws {Error} - Throws an error if sectionName is not provided or if map generation fails.
 */
async function getMap(
  mapName,
  vestmap,
  token,
  devMode = false,
  webmapID = null,
) {
  try {
    if (!mapName) {
      throw new Error('sectionName is required');
    }
    if (!token) {
      throw new Error('ArcGIS access_token is null');
    }

    let map;
    switch (mapName) {
      case 'msa_map':
        map = await getMap_MSA(vestmap, token, devMode);
        break;

      default:
        map = await fetchMapFromArcGIS(
          mapName,
          vestmap,
          token,
          devMode,
          webmapID,
        );
        break;
    }
    if (!map) {
      throw new Error('Map generation failed');
    }

    const mapLink = await storeMap(vestmap._id, mapName, map);
    if (!mapLink) {
      throw new Error('Failed to generate map link');
    }

    vestmap.assets.maps[mapName] = mapLink;

    return [mapName, mapLink];
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { getMap };
