import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Skeleton } from '@mui/material';
import React, { useContext, useState } from 'react';
import VestmapContext from '../../contexts/VestmapContext';
import globalStyles from '../globalStyles.module.css';
import Overlay from '../Overlay';
import styles from './demographic.module.css';
import groups from './groups.json';

import { useEffect } from 'react';
import { getMap } from '../../mapsUtil/mapFetcher';
import colors from './colors.json';

const Demographic = ({ demographics, address_info, map }) => {
  const { vestmap } = useContext(VestmapContext);

  // async function fetchMap() {
  //   const updatedVestmap = await getMap(
  //     'demographics_map',
  //     vestmap,
  //     arcgisToken,
  //   );
  //   if (updatedVestmap) {
  //     modifyVestmap(updatedVestmap);
  //   }
  // }

  useEffect(() => {
    if (vestmap?.map_flags?.demographics) {
      if (!map) {
        console.log('no map found in Demographic component');
      } else {
        console.log('map found in Demographic component');

        setDemoMapLoaded(false);
      }
    } else {
      setDemoMapLoaded(false);
    }
  }, [vestmap]);

  const [demoMapLoaded, setDemoMapLoaded] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  function GradeBlock() {
    return (
      <Box className={styles.demographics}>
        <div
          className={`${globalStyles.grayBox}`}
          style={updatedStyles.iconContainingDiv}
        >
          <h4
            style={{
              ...updatedStyles.subsectionHeading,
              fontWeight: '500',
            }}
          >
            Grade
          </h4>
          <h3>{demographics?.demographics_grade || 'N/A'}</h3>
          <HelpOutlineIcon
            onClick={() => {
              handleQuestionMarkClick('Grade');
              setIsOverlayVisible(true);
            }}
            sx={updatedStyles.helpoutlineIconStyle}
          />
          {activeOverlay === 'Grade' && (
            <div
              style={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '16rem',
                zIndex: '1000',
              }}
            >
              <Overlay
                isVisible={isOverlayVisible}
                onClose={() => {
                  setIsOverlayVisible(false);
                  handleCloseOverlay();
                }}
                keyElement={'Grade'}
              />
            </div>
          )}
        </div>
      </Box>
    );
  }

  function DemographicGroupBlock() {
    return (
      <Box
        className={styles.demographics}
        style={{
          position: 'relative',
        }}
      >
        <div style={updatedStyles.iconContainingDiv}>
          <h4 style={updatedStyles.subsectionHeading}>Demographic Group</h4>

          <HelpOutlineIcon
            onClick={() => {
              handleQuestionMarkClick('Demographic Group');
              setIsOverlayVisible(true);
            }}
            sx={updatedStyles.helpoutlineIconStyle}
          />

          {activeOverlay === 'Demographic Group' && (
            <div
              style={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '16rem',
                zIndex: '1000',
              }}
            >
              <Overlay
                isVisible={isOverlayVisible}
                onClose={() => {
                  setIsOverlayVisible(false);
                  handleCloseOverlay();
                }}
                keyElement={'Demographic Group'}
              />
            </div>
          )}
        </div>

        <a
          target={'_blank'}
          href={groups[demographics?.demographics_name]}
          rel='noreferrer'
        >
          <div
            style={{
              backgroundColor: `#${
                colors[demographics?.demographics_name] || '000000'
              }`,
              width: '1rem',
              height: '1rem',
              marginRight: '1rem',
              marginTop: '.5rem',
            }}
          ></div>
          <p>{demographics?.demographics_name}</p>
        </a>
      </Box>
    );
  }

  function NearestGroupsBlock() {
    return (
      <Box
        className={`${globalStyles.grayBox}`}
        sx={{
          paddingLeft: '2rem',
          '@media (max-width: 768px)': {
            display: 'flex',
            flexGrow: '0',
            width: '75%',
          },
        }}
      >
        <h3
          style={{
            ...updatedStyles.subsectionHeading,
            marginBottom: '0.5rem',
          }}
        >
          Nearest Groups
        </h3>
        <div className={`${styles.groupList}`}>
          {demographics?.demographics_3_5_mile?.length > 0 &&
            demographics?.demographics_3_5_mile
              .filter(
                (item, index) =>
                  demographics.demographics_3_5_mile.indexOf(item) === index,
              )
              .map((item, index) => (
                <a target={'_blank'} href={groups[item]} rel='noreferrer'>
                  <div
                    style={{
                      backgroundColor: `#${colors[item] || '000000'}`,

                      width: '1rem',
                      height: '1rem',
                      marginRight: '1rem',
                    }}
                  ></div>

                  <p>{item || 'N/A'}</p>
                </a>
              ))}
        </div>
      </Box>
    );
  }

  function WhoWeAreBlock() {
    return (
      <div className={styles.group3}>
        <div style={updatedStyles.iconContainingDiv}>
          <h4 style={updatedStyles.subsectionHeading}>Who we are</h4>
          <HelpOutlineIcon
            onClick={() => {
              handleQuestionMarkClick('Who we are');
              setIsOverlayVisible(true);
            }}
            sx={updatedStyles.helpoutlineIconStyle}
          />
          {activeOverlay === 'Who we are' && (
            <div
              style={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '16rem',
                zIndex: '1000',
              }}
            >
              <Overlay
                isVisible={isOverlayVisible}
                onClose={() => {
                  setIsOverlayVisible(false);
                  handleCloseOverlay();
                }}
                keyElement={'Who we are'}
              />
            </div>
          )}
        </div>
        <p>{demographics?.demographics_who_we_are || ''}</p>
      </div>
    );
  }

  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {demoMapLoaded && (
        <Skeleton
          variant='rectangular'
          sx={{
            height: '35rem',
            width: '100%',
          }}
          animation='wave'
        />
      )}
      <img
        src={map}
        className={globalStyles.mapContainer}
        alt=''
        onLoad={() => setDemoMapLoaded(false)}
        style={{ display: demoMapLoaded ? 'none' : 'block' }}
      />

      {/* <Box
        sx={{
          height: '35rem',
          width: '100%',
          // display: demoMapLoaded ? 'none' : 'block',
        }}
      >
        <WebMapComponent />
      </Box> */}

      <div className={styles.sectionContainer}>
        <Box
          className={globalStyles.fullRow}
          sx={{
            '@media (max-width: 768px)': {
              display: 'flex',
              gap: '1rem',
            },
          }}
        >
          <Box
            className={styles.section1Grid}
            sx={{
              '@media (max-width: 768px)': {
                width: '83%',
                margin: '0',
              },
            }}
          >
            {/* grade */}
            {vestmap?.outfields ? (
              vestmap.outfields?.demographics.includes('grade') && (
                <GradeBlock />
              )
            ) : (
              <GradeBlock />
            )}

            {/* demographic_group */}
            {vestmap?.outfields ? (
              vestmap.outfields?.demographics.includes('demographic_group') && (
                <DemographicGroupBlock />
              )
            ) : (
              <DemographicGroupBlock />
            )}
          </Box>

          {/* nearest_groups */}
          {vestmap?.outfields ? (
            vestmap.outfields?.demographics.includes('nearest_groups') && (
              <NearestGroupsBlock />
            )
          ) : (
            <NearestGroupsBlock />
          )}
        </Box>

        {/* who_we_are */}
        {vestmap?.outfields ? (
          vestmap.outfields?.demographics.includes('who_we_are') && (
            <WhoWeAreBlock />
          )
        ) : (
          <WhoWeAreBlock />
        )}
      </div>
    </div>
  );
};

export default Demographic;
