import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  CircularProgress,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState, useContext } from 'react';
import Hamburger from '../Hamburger';
import ErrorPopUp from '../pages/BulkVestmaps/ErrorPopUp';
import styles from '../VestmapViewer/viewer.module.css';
import { ReactComponent as AIIconDark } from '@/../../public/flaskDark.svg';
import HeaderImage from '../VestmapViewer/pdf_img/vestmap_logo.png';
import { useAuth } from '../../AuthProvider';
import CustomVestmapContext from '../contexts/CustomVestmapContext';
import { getMap } from '../mapsUtil/mapFetcher';

import { ReactComponent as GeneratePDFIconLight } from '@/../../public/pdfIconLight.svg';
import { ReactComponent as GenerateProReportIconLight } from '@/../../public/proReportIconLight.svg';

import { ReactComponent as DemoIconLight } from '@/../../public/demoIconLight.svg';
import { ReactComponent as DemoIconDark } from '@/../../public/demoIconDark.svg';

import { ReactComponent as IncomeIconLight } from '@/../../public/incomeIconLight.svg';
import { ReactComponent as IncomeIconDark } from '@/../../public/incomeIconDark.svg';

import { ReactComponent as HPIIconLight } from '@/../../public/hpiIconLight.svg';
import { ReactComponent as HPIIconDark } from '@/../../public/hpiIconDark.svg';

import { ReactComponent as SchoolsIconLight } from '@/../../public/schoolIconLight.svg';
import { ReactComponent as SchoolsIconDark } from '@/../../public/schoolIconDark.svg';

import { ReactComponent as CrimeIconLight } from '@/../../public/crimeIconLight.svg';
import { ReactComponent as CrimeIconDark } from '@/../../public/crimeIconDark.svg';

import { ReactComponent as ExpansionIconLight } from '@/../../public/expansionIconLight.svg';
import { ReactComponent as ExpansionIconDark } from '@/../../public/expansionIconDark.svg';

import { ReactComponent as RentIconLight } from '@/../../public/rentIconLight.svg';
import { ReactComponent as RentIconDark } from '@/../../public/rentIconDark.svg';

import { ReactComponent as NeighborhoodIconLight } from '@/../../public/neighborhoodIconLight.svg';
import { ReactComponent as NeighborhoodIconDark } from '@/../../public/neighborhoodIconDark.svg';

import { ReactComponent as MSAIconLight } from '@/../../public/msaIconLight.svg';
import { ReactComponent as MSAIconDark } from '@/../../public/msaIconDark.svg';

import { ReactComponent as AiAnalysisDark } from '@/../../public/flaskDark.svg';
import { ReactComponent as AiAnalysisLight } from '@/../../public/flaskLight.svg';

import { ReactComponent as DefaultDark } from '@/../../public/DefaultDark.svg';
import { ReactComponent as DefaultLight } from '@/../../public/DefaultLight.svg';

const CustomVestmapViewer = ({
  open,
  setOpen,
  vestmapData,
  openSnackbar,
  modifyVestmap,
  viewerState = state => {},
  mapsLoading,
  setMapsLoading,
  arcgisToken,
  getToken,
}) => {
  const [vestmap, setVestmap] = useState(vestmapData || {});
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [generatingPro, setGeneratingPro] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef([]);

  const [pdf, setPdf] = useState(
    vestmapData?.assets?.pdf || vestmapData?.pdf || null,
  );
  const [proReport, setProReport] = useState(
    vestmapData?.assets?.pro_report_path ||
      vestmapData?.pro_report_path ||
      null,
  );

  const [limitError, setLimitError] = useState(false);
  const [errorString, setErrorString] = useState('');

  const generatePro = async () => {
    if (generatingPro) return;
    setGeneratingPro(true);
    try {
      console.log('[Pro Report PDF Generation Started]');
      const url = process.env.REACT_APP_NODE_URL + `/report/request-pro-report`;

      const body = {
        userID: vestmap.MongodbUserID,
        address: vestmap.addressInfo.address,
        vestmapID: vestmap._id,
        bedrooms: +vestmap.bedrooms || 1,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      if (response.status === 403) {
        setErrorString('Pro reports Limit Exceeded');
        setLimitError(true);
        return;
      } else if (response.status === 401) {
        setErrorString('Your plan has expired.');
        setLimitError(true);
        return;
      } else if (response.status === 203) {
        const data = await response.json();
        const { proReportPath } = data;

        setVestmap(prev => {
          const asset = prev?.assets || {};
          return {
            ...prev,
            assets: { ...asset, pro_report_path: proReportPath },
          };
        });

        setProReport(prev => {
          return proReportPath;
        });

        openSnackbar('success');
        return;
      }

      if (!response.ok)
        throw new Error('Something went wrong. Please try again.');

      const data = await response.json();
      const { proReportPath } = data;

      setVestmap(prev => {
        const asset = prev.assets || {};
        return {
          ...prev,
          assets: { ...asset, pro_report_path: proReportPath },
        };
      });

      setProReport(prev => {
        return proReportPath;
      });

      if (localStorage) {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        user.pro_searches_remaining -= 1;
        localStorage.setItem('mongodbUser', JSON.stringify(user));
      }

      openSnackbar('success');
    } catch (error) {
      console.log('Error happened here');
      setGeneratingPro(false);

      console.log({ error });
      openSnackbar('pro');
    } finally {
      setGeneratingPro(false);
    }
  };

  const viewPro = () => {
    if (generatingPro) return;

    window.open(proReport, '_blank');
  };

  const viewPDF = async () => {
    if (generatingPDF) return;

    if (pdf.includes('googleapis')) {
      window.open(pdf, '_blank');
      return;
    }

    const path = 'https://oldapp.vestmap.com/pdfs/' + pdf;
    window.open(path, '_blank');
  };

  const generatePDF = async () => {
    if (generatingPDF) return;
    setGeneratingPDF(true);

    try {
      console.log('[PDF Generation Started]');

      const url = process.env.REACT_APP_NODE_URL + `/pdf/request-pdf`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ vestmap_json: vestmap }),
      });

      if (response.status === 401) {
        setErrorString('Your plan has expired.');
        setLimitError(true);
        return;
      } else if (response.status === 203) {
        // 203 means it already exists
        const data = await response.json();
        const { link } = data;

        setVestmap(prev => {
          const asset = prev?.assets || {};
          return { ...prev, assets: { ...asset, pdf: link } };
        });

        setPdf(prev => {
          return link;
        });

        openSnackbar('success');
        return;
      }

      const data = await response.json();

      if (!data.status) {
        console.log('Error happened here');
        console.log({ data });
        throw new Error('Something went wrong. Please try again.');
      }
      console.log({ vestmap });

      setVestmap(prev => {
        const asset = prev?.assets || {};
        return { ...prev, assets: { ...asset, pdf: data?.link } };
      });

      setPdf(prev => {
        return data?.link;
      });

      openSnackbar('success');
    } catch (error) {
      console.log('Error happened here');
      setGeneratingPDF(false);

      console.log({ error });
      openSnackbar('pdf');
    } finally {
      setGeneratingPDF(false);
    }
  };

  useEffect(() => {
    if (pdf) {
      console.log('PDF is present');
      const asset = vestmap?.assets || {};
      modifyVestmap({ ...vestmap, assets: { ...asset, pdf } });
    }
    if (proReport) {
      console.log('Pro Report is present');
      const asset = vestmap?.assets || {};
      modifyVestmap({
        ...vestmap,
        assets: { ...asset, pro_report_path: proReport },
      });
    }
  }, [pdf, proReport]);

  useEffect(() => {
    setVestmap(vestmapData);
  }, [vestmapData]);

  useEffect(() => {
    console.log({ vestmapData });
    setPdf(vestmap?.assets?.pdf || vestmap?.pdf);
    setProReport(vestmap?.assets?.pro_report_path || vestmap?.pro_report_path);
  }, []);

  useEffect(() => {
    setPdf(vestmap?.assets?.pdf || vestmap?.pdf);
    setProReport(vestmap?.assets?.pro_report_path || vestmap?.pro_report_path);
  }, [vestmap]);

  const handleClose = () => {
    if (pdf) modifyVestmap({ ...vestmap, assets: { ...vestmap.assets, pdf } });
    if (proReport)
      modifyVestmap({
        ...vestmap,
        assets: { ...vestmap.assets, pro_report_path: proReport },
      });

    // clear all states before closing
    setVestmap({});
    setGeneratingPDF(false);
    setGeneratingPro(false);

    setOpen(false);
    viewerState(false);
  };
  function closeLimitError() {
    setLimitError(false);
  }

  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {}, [sidebarOpen]);
  const [currentSection, setCurrentSection] = useState(null);

  useEffect(() => {
    setSidebarOpen(false);
  }, [currentSection]);

  return (
    <CustomVestmapContext.Provider
      value={{
        vestmap,
        setVestmap,
        modifyVestmap,
        generatePDF,
        viewPDF,
        generatePro,
        viewPro,
        generatingPDF,
        generatingPro,
        arcgisToken,
        mapsLoading,
        setMapsLoading,
        getToken,
        sectionRefs,
        activeSection,
        setActiveSection,
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='body'
        fullWidth={true}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        className={styles.dialog}
        PaperProps={{
          sx: {
            boxSizing: 'border-box',
            borderRadius: {
              sm: '20px',
              xs: '0px',
            },
            overflow: {
              xs: sidebarOpen && 'hidden',
            },
          },
        }}
        fullScreen={true}
        id='viewerDialogBox'
        // only for small screens
        onClick={() => {
          if (sidebarOpen) {
            setSidebarOpen(false);
          }
        }}
      >
        {limitError && (
          <ErrorPopUp closeLimitError={closeLimitError} error={errorString} />
        )}

        <DialogContent className={styles.dialogContent}>
          <Box
            sx={{
              display: {
                xs: 'flex',
                sm: 'grid',
              },
              gridTemplateColumns: {
                xs: '100%',
                sm: '22% 78%',
                md: '18% 82%',
              },
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block',
                },
                position: 'sticky',
                top: 0,
                height: '100vh',
                overflowY: 'auto',
              }}
            >
              <Sidebar />
            </Box>

            {/* For smaller screen */}
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                },

                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 100,
                width: '60%',
                height: '100%',
                overflowY: 'auto',
                transition: 'transform 0.3s',
                transform: {
                  xs: sidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
                  sm: 'none',
                },
              }}
            ></Box>

            <Box
              sx={{
                overflowY: {
                  sm: 'auto',
                  xs: 'none',
                },
                height: {
                  sm: '100vh',
                },

                scrollbarWidth: 'none',
              }}
            >
              <DialogTitle
                id='scroll-dialog-title'
                className={styles.dialogHeader}
                sx={{
                  backgroundColor: '#F9F9F9',
                }}
              >
                <IconButton
                  aria-label='close'
                  onClick={setSidebarOpen.bind(this, !sidebarOpen)}
                  sx={{
                    position: 'absolute',
                    display: { xs: 'block', sm: 'none' },
                    left: 11,
                    top: 11,
                    height: '40px',
                    width: '40px',
                    zIndex: 1001,
                    color: theme => theme.palette.grey[500],
                  }}
                >
                  <Hamburger isOpen={sidebarOpen} headerFlag={false} />
                </IconButton>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '40px',
                    padding: '24px',
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        sm: '100%',
                        xs: '220px',
                      },
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={HeaderImage} alt='' />
                  </Box>
                  <div className={styles.dialogHeaderChild}>
                    <h2>{vestmap?.address_info?.address || ''}</h2>
                  </div>
                </Box>

                <IconButton
                  aria-label='close'
                  onClick={handleClose}
                  sx={{
                    position: 'fixed',
                    right: 11,
                    top: 11,
                    zIndex: 4,
                    height: '40px',
                    width: '40px',
                    color: theme => theme.palette.grey[500],
                  }}
                >
                  <Hamburger isOpen={true} headerFlag={false} />
                </IconButton>
              </DialogTitle>
              <VestmapAccordion
                vestmap={vestmap}
                modifyVestmap={modifyVestmap}
                mapsLoading={mapsLoading}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Box
            sx={{
              width: {
                sm: '79.7%',
                xs: '100%',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: {
                sm: '18px',
                xs: '14px',
              },

              gap: {
                sm: '16px',
                xs: '12px',
              },
            }}
          >
            <Box
              sx={{
                color: '#84939C',
                fontFamily: 'Lato',
                fontWeight: '700',
              }}
            >
              Custom Report Generated by
            </Box>
            <Box
              sx={{
                width: {
                  sm: '200px',
                  xs: '150px',
                },
                height: {
                  sm: '30px',
                  xs: '27px',
                },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={HeaderImage} alt='Vestmap Logo' />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </CustomVestmapContext.Provider>
  );
};

function VestmapAccordion({ vestmap, modifyVestmap }) {
  const { arcgisToken, sectionRefs, setActiveSection } =
    useContext(CustomVestmapContext);
  const { getAccessToken } = useAuth();

  // Initialize local map loader state for each section
  const [localMapLoader, setLocalMapLoader] = useState(
    vestmap.sections.reduce((acc, section) => {
      acc[section.key] = section.map === '' ? true : false;
      return acc;
    }, {}),
  );

  async function fetchReportMaps() {
    console.log({ fetchMapsVestmap: vestmap });

    const emptyMapSections = getEmptyMapSections();
    if (emptyMapSections.length === 0) return;

    try {
      let tempToken = await getArcgisToken();

      const promiseArray = emptyMapSections.map(async section => {
        setLocalMapLoader(prevState => ({ ...prevState, [section.key]: true }));

        const response = await getMap(
          `${section.key}_map`,
          vestmap,
          tempToken,
          false,
          section.webmapID,
        );

        const updatedSection = { ...section, map: response[1] };

        const updatedVestmap = vestmap;
        updatedVestmap.sections = vestmap.sections.map(section => {
          if (section.key === updatedSection.key) {
            return updatedSection;
          }
          return section;
        });

        modifyVestmap(updatedVestmap);

        setLocalMapLoader(prevState => ({
          ...prevState,
          [section.key]: false,
        }));
        return null;
      });

      await Promise.all(promiseArray);

      console.log('Maps fetched successfully');
    } catch (error) {
      console.error('Error in fetchReportMaps:', error);
    }
  }

  function getEmptyMapSections() {
    return vestmap.sections.filter(section => section.map === '');
  }

  async function getArcgisToken() {
    if (arcgisToken) return arcgisToken;

    try {
      const accessToken = await getAccessToken();
      const url = process.env.REACT_APP_NODE_URL + '/admin/token';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Response error');

      const data = await response.json();
      if (data?.token) {
        return data.token.access_token;
      }

      throw new Error('Token not found');
    } catch (error) {
      console.error('Error getting token:', error);
      throw error;
    }
  }

  useEffect(() => {
    fetchReportMaps();
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null, // null makes it observe the entire viewport
      rootMargin: '0px',
      threshold: [0.5, 1], // 50% of the section must be visible
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.getAttribute('id'));
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      sectionRefs.current.forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  useEffect(() => {
    console.log({ inner: vestmap });
  }, [vestmap]);

  const iconStyle = {
    height: '2.25rem',
    width: '2.25rem',
  };

  if (!vestmap) return null;

  return (
    <Box sx={{ padding: { xs: '0', sm: '0px 24px 20px 24px' } }}>
      {vestmap.sections.map((section, index) => (
        <Accordion
          key={index}
          defaultExpanded={true}
          slotProps={{ unmountOnExit: true }}
          sx={{
            borderRadius: '16px !important',
            boxShadow: '5px 20px 30px 0px #E5E5E5B2',
            scrollbarWidth: 'none',
          }}
          ref={el => (sectionRefs.current[index] = el)} // Assign the ref to the section
          id={section.key}
        >
          <AccordionSummary
            id={`panel${index}bh-header`}
            sx={{ pointerEvents: 'none' }}
          >
            <div style={{ marginRight: '1rem' }}>
              {{
                demographics: <DemoIconDark style={iconStyle} />,

                income: <IncomeIconDark style={iconStyle} />,

                hpi: <HPIIconDark style={iconStyle} />,
                schools: <SchoolsIconDark style={iconStyle} />,
                crime: <CrimeIconDark style={iconStyle} />,
                expansion: <ExpansionIconDark style={iconStyle} />,
                rent: <RentIconDark style={iconStyle} />,
                neighborhood: <NeighborhoodIconDark style={iconStyle} />,
                msa: <MSAIconDark style={iconStyle} />,
                ai_analysis: <AiAnalysisDark style={iconStyle} />,
              }[section.key] || <DefaultDark style={iconStyle} />}
            </div>
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {section.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px',
              }}
            >
              {localMapLoader[section.key] ? (
                <Skeleton
                  variant='rectangular'
                  sx={{ height: '35rem', width: '100%' }}
                  animation='wave'
                />
              ) : (
                <>
                  <img
                    src={section.map}
                    alt=''
                    style={{
                      display: 'block',
                    }}
                  />
                </>
              )}
            </Box>

            {section.dynamicBlocks
              .reduce((rows, block, idx) => {
                if (idx % 3 === 0) rows.push([]);
                rows[rows.length - 1].push(block);
                return rows;
              }, [])
              .map((row, rowIndex) => (
                <Box
                  key={rowIndex}
                  sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
                >
                  {row.map((block, blockIndex) => (
                    <GenericBlock key={blockIndex} sectionBlock={block} />
                  ))}
                </Box>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

function Sidebar() {
  const {
    vestmap,
    sectionRefs,
    activeSection,
    pdfStatus,
    proReportStatus,
    handleGeneratePDF,
    handleGenerateProReport,
    viewPDF,
    viewPro,
    generatingPDF,
    generatingPro,
  } = useContext(CustomVestmapContext);

  const [selectedSection, setSelectedSection] = useState(
    vestmap.sections[0].key,
  );

  const styles = {
    selected: {
      backgroundColor: '#39585C',
      color: '#FFFFFF',
    },

    unselected: {
      color: '#39585C',
      fontWeight: 400,
      fontStyle: 'normal',
    },

    itemBoxStyle: {
      display: 'flex',
      height: '1.1rem',
      padding: '1rem',
      alignItems: 'center',
      gap: '0.75rem',
      alignSelf: 'stretch',
      borderRadius: '0.75rem',
    },

    itemTextStyle: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        xs: '0.875rem',
        sm: '.75rem',
        lg: '0.875rem',
      },
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '150%',
      letterSpacing: '0.0125rem',
    },

    upperBoxInnerStyle: {
      boxSizing: 'border-box',
      backgroundColor: '#4FA490',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.75rem',
      borderRadius: '1rem',
      padding: '0.75rem 0.2rem 0.75rem 0.2rem',
      cursor: 'pointer',
    },

    upperBoxTypography: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        sm: '16px',
        xs: '0.8rem',
      },
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.2px',
      textAlign: 'center',
      color: '#FFFFFF',
    },

    selectedBox: {
      backgroundColor: '#39585C',
    },
    selectedText: {
      color: '#FFFFFF',
      fontWeight: 800,
      textAlign: 'left',

      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedText: {
      color: '#39585C',
      fontWeight: 400,
      textAlign: 'left',
      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedBox: {
      backgroundColor: '#F9F9F9',
    },
    iconStyle: {
      width: '1.5rem',
      height: '1.5rem',
    },
    lowerBoxInnerStyle: {
      boxSizing: 'border-box',

      backgroundColor: '#4FA490',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      borderRadius: '1.2rem',
      padding: '16px',

      cursor: 'pointer',
    },
  };

  const handleClick = index => {
    console.log({ click: vestmap.sections[index].key });
    setSelectedSection(vestmap.sections[index].key);

    sectionRefs.current[index]?.scrollIntoView({});
  };

  useEffect(() => {
    if (activeSection) {
      setSelectedSection(activeSection);
    }
  }, [activeSection]);
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100vh',
        overflowY: 'auto',
        width: '18rem',

        backgroundColor: '#F9F9F9',
        display: 'flex',
        gap: '2rem',
        flexDirection: 'column',
        alignItems: 'center',

        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          marginTop: '13.5rem',
          marginBottom: '5rem',
          width: '85%',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        {vestmap?.templateName === 'Default (DISCERN)' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.8rem',
              width: '96%',
            }}
          >
            <Box
              sx={styles.upperBoxInnerStyle}
              onClick={() => {
                if (pdfStatus === undefined) {
                  handleGeneratePDF();
                } else {
                  viewPDF();
                }
              }}
            >
              {generatingPDF ? (
                <CircularProgress
                  size={'1.5rem'}
                  thickness={4.6}
                  style={{ color: '#fff' }}
                />
              ) : (
                <GeneratePDFIconLight />
              )}
              <Typography sx={styles.upperBoxTypography}>
                {pdfStatus === undefined ? 'Generate PDF' : 'View PDF'}
              </Typography>
            </Box>

            <Box
              sx={styles.upperBoxInnerStyle}
              onClick={() => {
                if (proReportStatus === undefined) {
                  handleGenerateProReport();
                } else {
                  viewPro();
                }
              }}
            >
              {generatingPro ? (
                <CircularProgress
                  size={'1.5rem'}
                  thickness={4.6}
                  style={{ color: '#fff' }}
                />
              ) : (
                <GenerateProReportIconLight />
              )}
              <Typography sx={styles.upperBoxTypography}>
                {proReportStatus === undefined
                  ? 'Generate Pro Report'
                  : 'View Pro Report'}
              </Typography>
            </Box>
          </Box>
        )}

        {/* More Green Box Group */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '96%',
            position: 'sticky',
            top: '0',
          }}
        >
          {vestmap.sections.map((section, index) => (
            <Box
              key={section.key}
              sx={[
                styles.lowerBoxInnerStyle,
                selectedSection === section.key
                  ? styles.selectedBox
                  : styles.unselectedBox,
              ]}
              onClick={() => handleClick(index)}
            >
              <Box>
                {{
                  demographics:
                    selectedSection === section.key ? (
                      <DemoIconLight style={styles.iconStyle} />
                    ) : (
                      <DemoIconDark style={styles.iconStyle} />
                    ),
                  income:
                    selectedSection === section.key ? (
                      <IncomeIconLight style={styles.iconStyle} />
                    ) : (
                      <IncomeIconDark style={styles.iconStyle} />
                    ),
                  hpi:
                    selectedSection === section.key ? (
                      <HPIIconLight style={styles.iconStyle} />
                    ) : (
                      <HPIIconDark style={styles.iconStyle} />
                    ),
                  schools:
                    selectedSection === section.key ? (
                      <SchoolsIconLight style={styles.iconStyle} />
                    ) : (
                      <SchoolsIconDark style={styles.iconStyle} />
                    ),
                  crime:
                    selectedSection === section.key ? (
                      <CrimeIconLight style={styles.iconStyle} />
                    ) : (
                      <CrimeIconDark style={styles.iconStyle} />
                    ),
                  expansion:
                    selectedSection === section.key ? (
                      <ExpansionIconLight style={styles.iconStyle} />
                    ) : (
                      <ExpansionIconDark style={styles.iconStyle} />
                    ),
                  rent:
                    selectedSection === section.key ? (
                      <RentIconLight style={styles.iconStyle} />
                    ) : (
                      <RentIconDark style={styles.iconStyle} />
                    ),
                  neighborhood:
                    selectedSection === section.key ? (
                      <NeighborhoodIconLight style={styles.iconStyle} />
                    ) : (
                      <NeighborhoodIconDark style={styles.iconStyle} />
                    ),
                  msa:
                    selectedSection === section.key ? (
                      <MSAIconLight style={styles.iconStyle} />
                    ) : (
                      <MSAIconDark style={styles.iconStyle} />
                    ),
                  ai_analysis:
                    selectedSection === section.key ? (
                      <AiAnalysisLight style={styles.iconStyle} />
                    ) : (
                      <AiAnalysisDark style={styles.iconStyle} />
                    ),
                }[section.key] ||
                  (selectedSection === section.key ? (
                    <DefaultLight style={styles.iconStyle} />
                  ) : (
                    <DefaultDark style={styles.iconStyle} />
                  ))}
              </Box>
              <Typography
                sx={
                  selectedSection === section.key
                    ? styles.selectedText
                    : styles.unselectedText
                }
              >
                {section.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function GenericBlock({ sectionBlock }) {
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
  };
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={[
          styles.text,
          {
            fontSize: '1.1rem',
            fontWeight: 500,
            color: '#39585C',
          },
        ]}
      >
        {sectionBlock.title}
      </Typography>
      <Typography
        sx={[
          styles.text,
          {
            fontSize: '1.4rem',
            fontWeight: 900,
          },
        ]}
      >
        {sectionBlock?.value
          ? sectionBlock.outfields.map(outfield => {
              if (!sectionBlock.value[outfield]) {
                return 'N/A';
              }
              return sectionBlock.value[outfield];
            })
          : 'N/A'}
      </Typography>

      {sectionBlock?.tooltip && <QuestionMarkSVG />}
    </Box>
  );
}

function QuestionMarkSVG() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='#4FA490'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-circle-help'
    >
      <circle cx='12' cy='12' r='10' />
      <path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3' />
      <path d='M12 17h.01' />
    </svg>
  );
}
export default CustomVestmapViewer;
