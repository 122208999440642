import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Skeleton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import VestmapContext from '../../contexts/VestmapContext';
import { getMap } from '../../mapsUtil/mapFetcher';
import CustomMarkdown from '../CustomMarkdown';
import globalStyles from '../globalStyles.module.css';
import Overlay from '../Overlay';
import styles from './neighborhood.module.css';

const Neighborhood = ({
  neighborhood,
  address_info,
  NeighborhoodStats1,
  NeighborhoodStats2,
  NeighborhoodStats3,
  NeighborhoodStats4,
  NeighborhoodStats5,
  NeighborhoodStats6,
  NeighborhoodStats7,
  NeighborhoodStats8,
  map,
}) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [demoMapLoaded, setDemoMapLoaded] = useState(true);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  const { arcgisToken, vestmap, modifyVestmap } = useContext(VestmapContext);

  async function fetchMap() {
    const updatedVestmap = await getMap(
      'neighborhood_map',
      vestmap,
      arcgisToken,
    );
    if (updatedVestmap) {
      modifyVestmap(updatedVestmap);
    }
  }

  useEffect(() => {
    if (vestmap?.map_flags?.neighborhood) {
      if (!map) {
        console.log('no map found in neighborhood component');
      } else {
        console.log('map found in neighborhood component');
        setDemoMapLoaded(false);
      }
    } else {
      setDemoMapLoaded(false);
    }
  }, [vestmap]);

  function NearestStores() {
    return (
      <div>
        <h2
          style={{
            margin: '1rem auto 2rem',

            ...updatedStyles.subsectionHeading,
            position: 'relative',
          }}
        >
          Nearest Stores
          <HelpOutlineIcon
            onClick={() => {
              handleQuestionMarkClick('Nearest Stores');
              setIsOverlayVisible(true);
            }}
            style={updatedStyles.helpoutlineIconStyle}
          />
          {activeOverlay === 'Nearest Stores' && (
            <div
              style={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '16rem',
                zIndex: '1000',
              }}
            >
              <Overlay
                isVisible={isOverlayVisible}
                onClose={() => {
                  setIsOverlayVisible(false);
                  handleCloseOverlay();
                }}
                keyElement={'Nearest Stores'}
              />
            </div>
          )}
        </h2>
        {neighborhood?.neighborhood_group_2?.length > 0 &&
          neighborhood.neighborhood_group_2
            .slice(0, 3)
            .map(function (neighborhood, index) {
              return (
                <h3
                  style={{
                    margin: '1rem 0',
                    position: 'relative',
                    fontWeight: '500',
                    fontSize: '19.2px',
                    ...updatedStyles.iconContainingDiv,
                    justifyContent: 'center',
                  }}
                  key={index}
                >
                  {neighborhood}
                  <HelpOutlineIcon
                    onClick={() => {
                      handleQuestionMarkClick(`Store ${index + 1}`);
                      setIsOverlayVisible(true);
                    }}
                    style={updatedStyles.helpoutlineIconStyle}
                  />
                  {activeOverlay === `Store ${index + 1}` && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '0%',
                        left: '0%',
                        width: '16rem',
                        zIndex: '1000',
                      }}
                    >
                      <Overlay
                        isVisible={isOverlayVisible}
                        onClose={() => {
                          setIsOverlayVisible(false);
                          handleCloseOverlay();
                        }}
                        keyElement={`Store ${index + 1}`}
                      />
                    </div>
                  )}
                </h3>
              );
            })}
      </div>
    );
  }

  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {demoMapLoaded && (
        <Skeleton
          variant='rectangular'
          sx={{
            height: '35rem',
            width: '100%',
          }}
          animation='wave'
        />
      )}
      <img
        src={map}
        className={globalStyles.mapContainer}
        alt=''
        onLoad={() => setDemoMapLoaded(false)}
        style={{ display: demoMapLoaded ? 'none' : 'block' }}
      />
      <div className={styles.sectionContainer}>
        {(map || demoMapLoaded) && (
          <div className={styles.stats}>
            <div className={styles.statCol}>
              <div>
                <img src={NeighborhoodStats1} alt='Dollar General' />
                <div>Dollar General</div>
              </div>
              <div>
                <img src={NeighborhoodStats2} alt='Dollar Tree' />
                <div>Dollar Tree</div>
              </div>
              <div>
                <img src={NeighborhoodStats3} alt='Family Dollar' />
                <div>Family Dollar</div>
              </div>
            </div>
            <div className={styles.statCol}>
              <div>
                <img src={NeighborhoodStats4} alt='Walmart' />
                <div>Walmart</div>
              </div>
              <div>
                <img src={NeighborhoodStats5} alt='Target' />
                <div>Target</div>
              </div>
              <div>
                <img src={NeighborhoodStats6} alt='Home Depot' />
                <div>Home Depot</div>
              </div>
            </div>
            <div className={styles.statCol}>
              <div>
                <img src={NeighborhoodStats7} alt='Starbucks' />
                <div>Starbucks</div>
              </div>
              <div>
                <img src={NeighborhoodStats8} alt='Whole Foods' />
                <div>Whole Foods</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {vestmap?.outfields ? (
        vestmap.outfields.neighborhood.includes('nearest_stores') && (
          <NearestStores />
        )
      ) : (
        <NearestStores />
      )}

      <CustomMarkdown sectionName='neighborhood' />
    </div>
  );
};

export default Neighborhood;
