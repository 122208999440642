import styles from './crime.module.css';
import globalStyles from '../globalStyles.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import Overlay from '../Overlay';
import { Skeleton } from '@mui/material';
import CustomMarkdown from '../CustomMarkdown';
import VestmapContext from '../../contexts/VestmapContext';
import { useContext, useEffect } from 'react';
import { getMap } from '../../mapsUtil/mapFetcher';

const Crime = ({
  crime,
  address_info,
  CrimeStats1,
  CrimeStats2,
  CrimeStats3,
  CrimeStats4,
  CrimeStats5,
  CrimeStats6,
  map,
}) => {
  const { vestmap } = useContext(VestmapContext);

  useEffect(() => {
    if (vestmap?.map_flags?.crime) {
      if (!map) {
        console.log('no map found in Crime component');
      } else {
        console.log('map found in Crime component');
        setDemoMapLoaded(false);
      }
    } else {
      setDemoMapLoaded(false);
    }
  }, [vestmap]);

  const crime_arr = [
    'CRMCYTOTC',
    'CRMCYPERC',
    'CRMCYMURD',
    'CRMCYRAPE',
    'CRMCYROBB',
    'CRMCYASST',
    'CRMCYPROC',
    'CRMCYBURG',
    'CRMCYLARC',
    'CRMCYMVEH',
  ];

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);
  const [demoMapLoaded, setDemoMapLoaded] = useState(true);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  function OverallCrimeIndexScore() {
    return (
      <div
        className={globalStyles.grayBox}
        style={{
          position: 'relative',
          padding: '.5rem 0',
        }}
      >
        <h3
          style={{
            margin: '.5rem',
            ...updatedStyles.subsectionHeading,
            fontWeight: '900',
            color: 'black',
          }}
        >
          {formatMultiple(crime?.anchor_address_crime_index_score) || '0'}{' '}
        </h3>
        <p style={{ textAlign: 'center' }}> National Average </p>

        <h4
          style={{
            fontWeight: '500',
            fontSize: '17.6px',
            color: 'black',
          }}
        >
          {' '}
          Overall Crime Index Score
        </h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('Overall Crime Index Score');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '.25rem',
          }}
        />
        {activeOverlay === 'Overall Crime Index Score' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'Overall Crime Index Score'}
            />
          </div>
        )}
      </div>
    );
  }

  function PropertyCrimeIndexScore() {
    return (
      <div
        className={globalStyles.grayBox}
        style={{
          position: 'relative',
          padding: '.5rem 0',
        }}
      >
        <h3
          style={{
            margin: '.5rem',
            ...updatedStyles.subsectionHeading,
            fontWeight: '900',
            color: 'black',
          }}
        >
          {formatMultiple(crime?.property_crime_index_score) || '0'}
        </h3>
        <p
          style={{
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '500',
            color: 'black',
          }}
        >
          {' '}
          National Average{' '}
        </p>

        <h4
          style={{
            fontWeight: '500',
            fontSize: '17.6px',
            color: 'black',
          }}
        >
          {' '}
          Property Crime Index Score
        </h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('Property Crime Index Score');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '.25rem',
          }}
        />
        {activeOverlay === 'Property Crime Index Score' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'Property Crime Index Score'}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {demoMapLoaded && (
        <Skeleton
          variant='rectangular'
          sx={{
            height: '35rem',
            width: '100%',
          }}
          animation='wave'
        />
      )}
      <img
        src={map}
        className={globalStyles.mapContainer}
        alt=''
        onLoad={() => setDemoMapLoaded(false)}
        style={{ display: demoMapLoaded ? 'none' : 'block' }}
      />
      <div className={styles.sectionContainer}>
        {(map || demoMapLoaded) && (
          <div className={`${globalStyles.stats} ${styles.statsSection}`}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '.25rem',
              }}
            >
              <h2
                style={{
                  ...updatedStyles.subsectionHeading,
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                2023 Crime Index
              </h2>
              <h3
                style={{
                  ...updatedStyles.subsectionHeading,
                  fontWeight: '500',
                  fontSize: '16px',
                  color: '#4FA490',
                }}
              >
                Block Group
              </h3>
            </div>
            <div className={styles.statsIcons}>
              <div>
                <img src={CrimeStats1} /> {'410 and up (More than 4X Average)'}
              </div>
              <div>
                <img src={CrimeStats2} /> {'201 - 400 (More than 2X Average)'}
              </div>
              <div>
                <img src={CrimeStats3} /> {'101 - 200 (Above Average)'}
              </div>
              <div>
                <img src={CrimeStats4} /> {'51 - 100 (Below Average)'}
              </div>
              <div>
                <img src={CrimeStats5} /> {'1 - 50 (Half of Average)'}
              </div>
              <div>
                <img src={CrimeStats6} /> {'No Data'}
              </div>
            </div>
          </div>
        )}

        {/*Heading */}
        {vestmap?.outfields ? (
          vestmap.outfields.crime.length > 0 && (
            <h2
              style={{
                ...updatedStyles.subsectionHeading,
                fontSize: '24px',
                textAlign: 'center',
              }}
            >
              National Average Crime Index{' '}
            </h2>
          )
        ) : (
          <h2
            style={{
              ...updatedStyles.subsectionHeading,
              fontSize: '24px',
              textAlign: 'center',
            }}
          >
            National Average Crime Index{' '}
          </h2>
        )}

        <div className={styles.mainBlock}>
          {/*Top Blocks */}

          {vestmap?.outfields ? (
            vestmap.outfields.crime.includes('CRMCYTOTC') && (
              <OverallCrimeIndexScore />
            )
          ) : (
            <OverallCrimeIndexScore />
          )}

          {vestmap?.outfields ? (
            vestmap.outfields.crime.includes('CRMCYPROC') && (
              <PropertyCrimeIndexScore />
            )
          ) : (
            <PropertyCrimeIndexScore />
          )}
        </div>

        {vestmap?.outfields ? (
          vestmap.outfields.crime.length > 0 && <h2>Crime Index </h2>
        ) : (
          <h2>Crime Index </h2>
        )}
        <div className={styles.mainBlock}>
          {/*Smaller Blocks */}

          {Array.isArray(crime_arr) && crime_arr.length > 0
            ? crime_arr.map(function (thisCrime, index) {
                if (vestmap?.outfields) {
                  if (!vestmap.outfields.crime.includes(thisCrime)) return null;
                }

                let crimeTitle;
                if (thisCrime === 'CRMCYRAPE')
                  crimeTitle = '2023 Sexual Assault Index (AGS)';
                else
                  crimeTitle = crime?.crime_fieldAliases[thisCrime]?.replace(
                    '2021',
                    '2023',
                  );

                return (
                  <div
                    key={index}
                    className={styles.singleBlock}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <h4>{crimeTitle || ''}</h4>
                    <h3
                      style={{
                        ...updatedStyles.subsectionHeading,
                        color: 'black',
                        fontSize: '17.6px',
                      }}
                    >
                      {formatMultiple(crime?.crime_attributes[thisCrime])}
                    </h3>
                    <p style={{ textAlign: 'center' }}> National Average </p>
                    <HelpOutlineIcon
                      onClick={() => {
                        handleQuestionMarkClick(thisCrime);
                        setIsOverlayVisible(true);
                      }}
                      style={{
                        ...updatedStyles.helpoutlineIconStyle,
                        paddingTop: '.25rem',
                      }}
                    />
                    {activeOverlay === thisCrime && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0%',
                          left: '0%',
                          width: '16rem',
                          zIndex: '1000',
                        }}
                      >
                        <Overlay
                          isVisible={isOverlayVisible}
                          onClose={() => {
                            setIsOverlayVisible(false);
                            handleCloseOverlay();
                          }}
                          keyElement={thisCrime}
                        />
                      </div>
                    )}
                  </div>
                );
              })
            : ''}
        </div>

        <CustomMarkdown sectionName='crime' />
      </div>
    </div>
  );
};

const formatMultiple = value => {
  if (!value) return 'N/A';
  const value2 = value / 100;
  return `${value2}x`;
};

export default Crime;
