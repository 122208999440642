import React from 'react';
import styles from './expansion.module.css';
import globalStyles from '../globalStyles.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import Overlay from '../Overlay';
import CustomMarkdown from '../CustomMarkdown';
import VestmapContext from '../../contexts/VestmapContext';
import { useContext, useEffect } from 'react';
import { getMap } from '../../mapsUtil/mapFetcher';

const Expansion = ({
  expansion,
  address_info,
  ExpansionStats1,
  ExpansionStats2,
  ExpansionStats3,
  ExpansionStats4,
  ExpansionStats5,
  ExpansionStats6,
  ExpansionStats7,
  map,
}) => {
  const { arcgisToken, vestmap, modifyVestmap } = useContext(VestmapContext);

  // async function fetchMap() {
  //   const updatedVestmap = await getMap('expansion_map', vestmap, arcgisToken);
  //   if (updatedVestmap) {
  //     modifyVestmap(updatedVestmap);
  //   }
  // }

  useEffect(() => {
    if (vestmap?.map_flags?.expansion) {
      if (!map) {
        console.log('no map found in expansion component');
      } else {
        console.log('map found in expansion component');
        setDemoMapLoaded(false);
      }
    } else {
      setDemoMapLoaded(false);
    }
  }, [vestmap]);

  const growth = num => {
    if (num >= 1.44) {
      return 'Meteoric Growth';
    }
    if (num >= 0.73 && num <= 1.43) {
      return 'Growing';
    }
    if (num >= -0.25 && num <= 0.72) {
      return 'Stable';
    }
    if (num >= -1 && num <= -0.26) {
      return 'Declining';
    }
    if (num < -1) {
      return 'Steeply Declining';
    } else {
      return '';
    }
  };

  const [demoMapLoaded, setDemoMapLoaded] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  function Block() {
    return (
      <div
        className={`${globalStyles.grayBox}`}
        style={{
          position: 'relative',
        }}
      >
        <h4>Block</h4>
        <h3
          style={{
            fontWeight: '900',
          }}
        >
          {expansion?.annual_forecasted_population_growth_block + '%' || ''}
        </h3>
        <h4>
          {growth(expansion?.annual_forecasted_population_growth_block) || ''}
        </h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('Block Expansion');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '1rem',
          }}
        />
        {activeOverlay === 'Block Expansion' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'Block Expansion'}
            />
          </div>
        )}
      </div>
    );
  }

  function Tract() {
    return (
      <div
        className={`${globalStyles.grayBox}`}
        style={{
          position: 'relative',
        }}
      >
        <h4>Tract</h4>
        <h3
          style={{
            fontWeight: '900',
          }}
        >
          {expansion?.annual_forecasted_population_growth_tract + '%' || ''}
        </h3>
        <h4>
          {growth(expansion?.annual_forecasted_population_growth_tract) || ''}
        </h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('Tract Expansion');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '1rem',
          }}
        />
        {activeOverlay === 'Tract Expansion' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'Tract Expansion'}
            />
          </div>
        )}
      </div>
    );
  }

  function Zip() {
    return (
      <div
        className={`${globalStyles.grayBox}`}
        style={{
          position: 'relative',
        }}
      >
        <h4>ZIP</h4>
        <h3
          style={{
            fontWeight: '900',
          }}
        >
          {expansion?.annual_forecasted_population_growth_zip + '%' || ''}
        </h3>
        <h4>
          {growth(expansion?.annual_forecasted_population_growth_zip) || ''}
        </h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('Zip Expansion');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '1rem',
          }}
        />
        {activeOverlay === 'Zip Expansion' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'Zip Expansion'}
            />
          </div>
        )}
      </div>
    );
  }

  function County() {
    return (
      <div
        className={`${globalStyles.grayBox}`}
        style={{
          position: 'relative',
        }}
      >
        <h4>County</h4>
        <h3
          style={{
            fontWeight: '900',
          }}
        >
          {expansion?.annual_forecasted_population_growth_county + '%'}
        </h3>
        <h4>
          {growth(expansion?.annual_forecasted_population_growth_county) || ''}
        </h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('County Expansion');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '1rem',
          }}
        />
        {activeOverlay === 'County Expansion' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'County Expansion'}
            />
          </div>
        )}
      </div>
    );
  }
  function State() {
    return (
      <div
        className={`${globalStyles.grayBox}`}
        style={{
          position: 'relative',
        }}
      >
        <h4>State</h4>
        <h3
          style={{
            fontWeight: '900',
          }}
        >
          {expansion?.annual_forecasted_state + '%' || ''}
        </h3>
        <h4>{growth(expansion?.annual_forecasted_state) || ''}</h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('State Expansion');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '1rem',
          }}
        />
        {activeOverlay === 'State Expansion' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'State Expansion'}
            />
          </div>
        )}
      </div>
    );
  }
  function National() {
    return (
      <div
        className={`${globalStyles.grayBox}`}
        style={{
          position: 'relative',
        }}
      >
        <h4>National</h4>
        <h3
          style={{
            fontWeight: '900',
          }}
        >
          {expansion?.annual_forecasted_population_growth_national + '%' || ''}
        </h3>
        <h4>
          {growth(expansion?.annual_forecasted_population_growth_national) ||
            ''}
        </h4>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('National Expansion');
            setIsOverlayVisible(true);
          }}
          style={{
            ...updatedStyles.helpoutlineIconStyle,
            paddingTop: '1rem',
          }}
        />
        {activeOverlay === 'National Expansion' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'National Expansion'}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {demoMapLoaded && (
        <Skeleton
          variant='rectangular'
          sx={{
            height: '35rem',
            width: '100%',
          }}
          animation='wave'
        />
      )}
      <img
        src={map}
        className={globalStyles.mapContainer}
        alt=''
        onLoad={() => setDemoMapLoaded(false)}
        style={{ display: demoMapLoaded ? 'none' : 'block' }}
      />
      <div className={styles.sectionContainer}>
        {(map || demoMapLoaded) && (
          <div className={styles.statsContainer}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <h2
                style={{
                  ...updatedStyles.subsectionHeading,
                  fontWeight: '700',
                  fontSize: '24px',
                }}
              >
                2023-2028 USA Population Growth
              </h2>
              <h3
                style={{
                  ...updatedStyles.subsectionHeading,
                  fontWeight: '500',
                  fontSize: '16px',
                  marginTop: '0.5rem',
                  color: '#4FA490',
                }}
              >
                Block Group
              </h3>
            </div>
            <div className={styles.stat2Legend}>
              <div>
                <img src={ExpansionStats1} /> {'> 1.44 Meteoric Growth'}
              </div>
              <div>
                <img src={ExpansionStats4} /> {'-1 - -0.26 Declining'}
              </div>
              <div>
                <img src={ExpansionStats2} /> {'0.73 - 1.43 Growing'}
              </div>
              <div>
                <img src={ExpansionStats5} /> {'< -1 Steeply Declining'}
              </div>
              <div>
                <img src={ExpansionStats3} /> {'-0.25 - 0.72 Stable'}
              </div>
            </div>
          </div>
        )}

        <div className={globalStyles.fullRow}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h2
              style={{
                margin: '2rem auto',
                ...updatedStyles.subsectionHeading,
                fontSize: '24px',
              }}
            >
              Annual Forecasted Population Growth
            </h2>
          </div>
          <div className={styles.section2}>
            {vestmap?.outfields ? (
              vestmap.outfields.expansion.map((item, index) => {
                switch (item) {
                  case 'block':
                    return <Block key={index} />;
                  case 'tract':
                    return <Tract key={index} />;
                  case 'zip':
                    return <Zip key={index} />;
                  case 'county':
                    return <County key={index} />;
                  case 'state':
                    return <State key={index} />;
                  case 'national':
                    return <National key={index} />;
                  default:
                    return null;
                }
              })
            ) : (
              <>
                <Block />
                <Tract />
                <Zip />
                <County />
                <State />
                <National />
              </>
            )}
          </div>
        </div>

        <CustomMarkdown sectionName='expansion' />
      </div>
    </div>
  );
};

export default Expansion;
