import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Skeleton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import VestmapContext from '../../contexts/VestmapContext';
import { getMap } from '../../mapsUtil/mapFetcher';
import CustomMarkdown from '../CustomMarkdown';
import globalStyles from '../globalStyles.module.css';
import Overlay from '../Overlay';
import styles from './schools.module.css';

const Schools = ({ schools, metropolitan_statistical_area, map }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);
  const [demoMapLoaded, setDemoMapLoaded] = useState(true);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '700',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  const { arcgisToken, vestmap, modifyVestmap } = useContext(VestmapContext);

  // async function fetchMap() {
  //   const updatedVestmap = await getMap('school_map', vestmap, arcgisToken);
  //   if (updatedVestmap) {
  //     modifyVestmap(updatedVestmap);
  //   }
  // }

  useEffect(() => {
    if (vestmap?.map_flags?.schools) {
      if (!map) {
        console.log('no map found in schools component');
      } else {
        console.log('map found in schools component');
        setDemoMapLoaded(false);
      }
    } else {
      setDemoMapLoaded(false);
    }
  }, [vestmap]);

  function DistrictNameBlock() {
    return (
      <div
        className={`${globalStyles.grayBox}`}
        style={{ flexGrow: 2, padding: '2.5rem 0' }}
      >
        <h4
          style={{
            ...updatedStyles.subsectionHeading,
            color: '#4FA490',
            fontWeight: '500',
            fontSize: '16px',
          }}
        >
          School District Name
        </h4>
        <h3
          style={{
            ...updatedStyles.subsectionHeading,
          }}
        >
          {schools?.district_name || 'N/A'}
        </h3>
      </div>
    );
  }

  function NearestThreeSchools() {
    return (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Box
          className={styles.heading}
          sx={{
            ...updatedStyles.subsectionHeading,
            ...updatedStyles.iconContainingDiv,
            fontWeight: '900',
            borderRadius: '16px',
            justifyContent: 'center',
            width: {
              sm: '20%',
              xs: '60%',
            },
            height: '4rem',
          }}
        >
          Nearest 3 Schools
          <HelpOutlineIcon
            onClick={() => {
              handleQuestionMarkClick('Nearest 3 Schools');
              setIsOverlayVisible(true);
            }}
            sx={updatedStyles.helpoutlineIconStyle}
          />
          {activeOverlay === 'Nearest 3 Schools' && (
            <div
              style={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '16rem',
                zIndex: '1000',
              }}
            >
              <Overlay
                isVisible={isOverlayVisible}
                onClose={() => {
                  setIsOverlayVisible(false);
                  handleCloseOverlay();
                }}
                keyElement={'Nearest 3 Schools'}
              />
            </div>
          )}
        </Box>
        <div className={styles.sectionPart}>
          {schools?.schools_nearest_3?.length > 0 &&
            schools?.schools_nearest_3?.map((item, index) => (
              <div>
                <h4
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontWeight: '500',
                    color: 'black',
                    fontSize: '19.2px',
                  }}
                >
                  {item.name || ''}
                </h4>
                <a key={index} href={item.url} target='_blank' rel='noreferrer'>
                  {item.rating || ''}
                </a>
              </div>
            ))}
        </div>
      </Box>
    );
  }

  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {demoMapLoaded && (
        <Skeleton
          variant='rectangular'
          sx={{
            height: '35rem',
            width: '100%',
          }}
          animation='wave'
        />
      )}
      <img
        src={map}
        className={globalStyles.mapContainer}
        alt=''
        onLoad={() => setDemoMapLoaded(false)}
        style={{ display: demoMapLoaded ? 'none' : 'block' }}
      />
      <div className={styles.sectionContainer}>
        <div className={globalStyles.fullRow}>
          {vestmap?.outfields ? (
            vestmap.outfields.schools.includes('district_name') && (
              <DistrictNameBlock />
            )
          ) : (
            <DistrictNameBlock />
          )}
        </div>

        {vestmap?.outfields ? (
          vestmap.outfields.schools.includes('nearest_3_schools') && (
            <NearestThreeSchools />
          )
        ) : (
          <NearestThreeSchools />
        )}

        <CustomMarkdown sectionName='schools' />
      </div>
    </div>
  );
};

export default Schools;
