import React, { useEffect, useRef, useState } from 'react';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import esriConfig from '@arcgis/core/config';
import Extent from '@arcgis/core/geometry/Extent';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import TileLayer from '@arcgis/core/layers/TileLayer';
const WebMapComponent = ({ webmapJSON }) => {
  const mapRef = useRef(null);

  const apiKey = 'removed for obvious reasons';

  const [mapLoaded, setMapLoaded] = useState(false);

  const fn = async () => {
    esriConfig.apiKey =
      'removed';

    const webmap = new WebMap({
      portalItem: {
        id: '716041d8fa6345b6b59a24f38ad9f54b',
      },
    });

    const extent = new Extent({
      xmax: -72.934036017064,
      xmin: -72.936036017064,
      ymax: 41.303848016519,
      ymin: 41.301848016519,
      spatialReference: {
        wkid: 4326,
      },
    });

    const view = new MapView({
      container: mapRef.current,
      map: webmap,

      center: [-72.935036017064, 41.302848016519],
      zoom: 5,
      

      extent: extent,
  

      // disable zooming and panning
      constraints: {
        snapToZoom: false,
        minZoom: 13,
        maxZoom: 13,
        rotationEnabled: false,
      },
      // remove the built-in UI components
      ui: {
        components: [],
      },



    });
    
    const testLayer = new FeatureLayer({
      portalItem : {
        id : "80e01dc520744b5cb2b36f22974d7972"
      },
      apiKey : apiKey
    });


    const zipCodesLayer = new FeatureLayer({
      id: '18a709db9cc-layer-0',
      url: 'https://services5.arcgis.com/9fQmObndozAJu9f5/arcgis/rest/services/ZipCode/FeatureServer/0',
      opacity: 1,
      minScale: 0,
      maxScale: 0,
      apiKey :  apiKey
    });


    const worldHillshadeLayer = new TileLayer({
      id:   'World_Hillshade_3805',
      url: 'https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer',
      opacity: 1,
      minScale: 0,
      maxScale: 0,
      apiKey :  apiKey,
      
    });



    const vectoreTileLayer = new TileLayer({
      id: 'VectorTile_2333',
      url: 'https://cdn.arcgis.com/sharing/rest/content/items/7dc6cea0b1764a1f9af2e679f642f0f5/resources/styles/root.json',
      opacity: 1,
      minScale: 0,
      maxScale: 0,
      apiKey :  apiKey
    });


    const blockLayer = new FeatureLayer({
      url: 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Census_BlockGroup_Population/FeatureServer/0',
      renderer: {
        type: 'uniqueValue',
        visualVariables: [
          {
            type: 'sizeInfo',
            valueExpression: '$view.scale',
            stops: [
              {
                size: 2,
                value: 19162,
              },
              {
                size: 1,
                value: 59881,
              },
              {
                size: 0.5,
                value: 239526,
              },
              {
                size: 0,
                value: 479052,
              },
            ],
            target: 'outline',
          },
        ],
        field1: 'TSEGNAME',
        defaultLabel: 'Other',
        defaultSymbol: {
          type: 'esriSFS',
          color: [170, 170, 170, 255],
          outline: {
            type: 'esriSLS',
            color: [153, 153, 153, 64],
            width: 0.75,
            style: 'esriSLSSolid',
          },
          style: 'esriSFSSolid',
        },
        uniqueValueGroups: [
          {
            classes: [
              {
                label: 'Southern Satellites',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Southern Satellites']],
              },
              {
                label: 'Salt of the Earth',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Salt of the Earth']],
              },
              {
                label: 'Green Acres',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Green Acres']],
              },
              {
                label: 'Savvy Suburbanites',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Savvy Suburbanites']],
              },
              {
                label: 'Heartland Communities',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Heartland Communities']],
              },
              {
                label: 'Workday Drive',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Workday Drive']],
              },
              {
                label: 'Midlife Constants',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Midlife Constants']],
              },
              {
                label: 'Pleasantville',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Pleasantville']],
              },
              {
                label: 'Comfortable Empty Nesters',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Comfortable Empty Nesters']],
              },
              {
                label: 'Rustbelt Traditions',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Rustbelt Traditions']],
              },
              {
                label: 'Traditional Living',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Traditional Living']],
              },
              {
                label: 'Middleburg',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Middleburg']],
              },
              {
                label: 'Rooted Rural',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Rooted Rural']],
              },
              {
                label: 'Small Town Sincerity',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Small Town Sincerity']],
              },
              {
                label: 'Old and Newcomers',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Old and Newcomers']],
              },
              {
                label: 'In Style',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['In Style']],
              },
              {
                label: 'Parks and Rec',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Parks and Rec']],
              },
              {
                label: 'Up and Coming Families',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Up and Coming Families']],
              },
              {
                label: 'Exurbanites',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Exurbanites']],
              },
              {
                label: 'Modest Income Homes',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Modest Income Homes']],
              },
              {
                label: 'Top Tier',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Top Tier']],
              },
              {
                label: 'Bright Young Professionals',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Bright Young Professionals']],
              },
              {
                label: 'Home Improvement',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Home Improvement']],
              },
              {
                label: 'Rural Bypasses',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Rural Bypasses']],
              },
              {
                label: 'The Great Outdoors',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['The Great Outdoors']],
              },
              {
                label: 'Hometown Heritage',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Hometown Heritage']],
              },
              {
                label: 'Front Porches',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Front Porches']],
              },
              {
                label: 'Professional Pride',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Professional Pride']],
              },
              {
                label: 'City Lights',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['City Lights']],
              },
              {
                label: 'Urban Edge Families',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Urban Edge Families']],
              },
              {
                label: 'Prairie Living',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Prairie Living']],
              },
              {
                label: 'Set to Impress',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Set to Impress']],
              },
              {
                label: 'Young and Restless',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Young and Restless']],
              },
              {
                label: 'Boomburbs',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Boomburbs']],
              },
              {
                label: 'Emerald City',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Emerald City']],
              },
              {
                label: 'Urban Villages',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Urban Villages']],
              },
              {
                label: 'Urban Chic',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Urban Chic']],
              },
              {
                label: 'Metro Fusion',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Metro Fusion']],
              },
              {
                label: 'Rural Resort Dwellers',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Rural Resort Dwellers']],
              },
              {
                label: 'Diverse Convergence',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Diverse Convergence']],
              },
              {
                label: 'Forging Opportunity',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Forging Opportunity']],
              },
              {
                label: 'Metro Renters',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Metro Renters']],
              },
              {
                label: 'Family Foundations',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Family Foundations']],
              },
              {
                label: 'Down the Road',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Down the Road']],
              },
              {
                label: 'Golden Years',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Golden Years']],
              },
              {
                label: 'City Commons',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['City Commons']],
              },
              {
                label: 'Enterprising Professionals',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Enterprising Professionals']],
              },
              {
                label: 'Retirement Communities',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Retirement Communities']],
              },
              {
                label: 'Southwestern Families',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Southwestern Families']],
              },
              {
                label: 'Trendsetters',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Trendsetters']],
              },
              {
                label: 'City Strivers',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['City Strivers']],
              },
              {
                label: 'College Towns',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['College Towns']],
              },
              {
                label: 'Family Extensions',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Family Extensions']],
              },
              {
                label: 'Senior Escapes',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Senior Escapes']],
              },
              {
                label: 'Laptops and Lattes',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Laptops and Lattes']],
              },
              {
                label: 'NeWest Residents',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['NeWest Residents']],
              },
              {
                label: 'Economic BedRock',
                symbol: {
                  type: 'esriSFS',
                  color: [60, 175, 153, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Economic BedRock']],
              },
              {
                label: 'Social Security Set',
                symbol: {
                  type: 'esriSFS',
                  color: [107, 107, 214, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Social Security Set']],
              },
              {
                label: 'Pacific Heights',
                symbol: {
                  type: 'esriSFS',
                  color: [181, 71, 121, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Pacific Heights']],
              },
              {
                label: 'Fresh Ambitions',
                symbol: {
                  type: 'esriSFS',
                  color: [127, 127, 127, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Fresh Ambitions']],
              },
              {
                label: 'Unclassified',
                symbol: {
                  type: 'esriSFS',
                  color: [237, 81, 81, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Unclassified']],
              },
              {
                label: 'Silver & Gold',
                symbol: {
                  type: 'esriSFS',
                  color: [20, 158, 206, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Silver & Gold']],
              },
              {
                label: 'Downtown Melting Pot',
                symbol: {
                  type: 'esriSFS',
                  color: [167, 198, 54, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Downtown Melting Pot']],
              },
              {
                label: 'Dorms to Diplomas',
                symbol: {
                  type: 'esriSFS',
                  color: [158, 85, 156, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Dorms to Diplomas']],
              },
              {
                label: 'The Elders',
                symbol: {
                  type: 'esriSFS',
                  color: [252, 146, 31, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['The Elders']],
              },
              {
                label: 'High Rise Renters',
                symbol: {
                  type: 'esriSFS',
                  color: [255, 222, 62, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['High Rise Renters']],
              },
              {
                label: 'Farm to Table',
                symbol: {
                  type: 'esriSFS',
                  color: [247, 137, 216, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Farm to Table']],
              },
              {
                label: 'Military Proximity',
                symbol: {
                  type: 'esriSFS',
                  color: [183, 129, 74, 255],
                  outline: {
                    type: 'esriSLS',
                    color: [153, 153, 153, 64],
                    width: 0.75,
                    style: 'esriSLSSolid',
                  },
                  style: 'esriSFSSolid',
                },
                values: [['Military Proximity']],
              },
            ],
          },
        ],
        uniqueValueInfos: [
          {
            label: 'Southern Satellites',
            symbol: {
              type: 'esriSFS',
              color: [237, 81, 81, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Southern Satellites',
          },
          {
            label: 'Salt of the Earth',
            symbol: {
              type: 'esriSFS',
              color: [20, 158, 206, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Salt of the Earth',
          },
          {
            label: 'Green Acres',
            symbol: {
              type: 'esriSFS',
              color: [167, 198, 54, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Green Acres',
          },
          {
            label: 'Savvy Suburbanites',
            symbol: {
              type: 'esriSFS',
              color: [158, 85, 156, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Savvy Suburbanites',
          },
          {
            label: 'Heartland Communities',
            symbol: {
              type: 'esriSFS',
              color: [252, 146, 31, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Heartland Communities',
          },
          {
            label: 'Workday Drive',
            symbol: {
              type: 'esriSFS',
              color: [255, 222, 62, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Workday Drive',
          },
          {
            label: 'Midlife Constants',
            symbol: {
              type: 'esriSFS',
              color: [247, 137, 216, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Midlife Constants',
          },
          {
            label: 'Pleasantville',
            symbol: {
              type: 'esriSFS',
              color: [183, 129, 74, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Pleasantville',
          },
          {
            label: 'Comfortable Empty Nesters',
            symbol: {
              type: 'esriSFS',
              color: [60, 175, 153, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Comfortable Empty Nesters',
          },
          {
            label: 'Rustbelt Traditions',
            symbol: {
              type: 'esriSFS',
              color: [107, 107, 214, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Rustbelt Traditions',
          },
          {
            label: 'Traditional Living',
            symbol: {
              type: 'esriSFS',
              color: [181, 71, 121, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Traditional Living',
          },
          {
            label: 'Middleburg',
            symbol: {
              type: 'esriSFS',
              color: [127, 127, 127, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Middleburg',
          },
          {
            label: 'Rooted Rural',
            symbol: {
              type: 'esriSFS',
              color: [237, 81, 81, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Rooted Rural',
          },
          {
            label: 'Small Town Sincerity',
            symbol: {
              type: 'esriSFS',
              color: [20, 158, 206, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Small Town Sincerity',
          },
          {
            label: 'Old and Newcomers',
            symbol: {
              type: 'esriSFS',
              color: [167, 198, 54, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Old and Newcomers',
          },
          {
            label: 'In Style',
            symbol: {
              type: 'esriSFS',
              color: [158, 85, 156, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'In Style',
          },
          {
            label: 'Parks and Rec',
            symbol: {
              type: 'esriSFS',
              color: [252, 146, 31, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Parks and Rec',
          },
          {
            label: 'Up and Coming Families',
            symbol: {
              type: 'esriSFS',
              color: [255, 222, 62, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Up and Coming Families',
          },
          {
            label: 'Exurbanites',
            symbol: {
              type: 'esriSFS',
              color: [247, 137, 216, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Exurbanites',
          },
          {
            label: 'Modest Income Homes',
            symbol: {
              type: 'esriSFS',
              color: [183, 129, 74, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Modest Income Homes',
          },
          {
            label: 'Top Tier',
            symbol: {
              type: 'esriSFS',
              color: [60, 175, 153, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Top Tier',
          },
          {
            label: 'Bright Young Professionals',
            symbol: {
              type: 'esriSFS',
              color: [107, 107, 214, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Bright Young Professionals',
          },
          {
            label: 'Home Improvement',
            symbol: {
              type: 'esriSFS',
              color: [181, 71, 121, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Home Improvement',
          },
          {
            label: 'Rural Bypasses',
            symbol: {
              type: 'esriSFS',
              color: [127, 127, 127, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Rural Bypasses',
          },
          {
            label: 'The Great Outdoors',
            symbol: {
              type: 'esriSFS',
              color: [237, 81, 81, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'The Great Outdoors',
          },
          {
            label: 'Hometown Heritage',
            symbol: {
              type: 'esriSFS',
              color: [20, 158, 206, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Hometown Heritage',
          },
          {
            label: 'Front Porches',
            symbol: {
              type: 'esriSFS',
              color: [167, 198, 54, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Front Porches',
          },
          {
            label: 'Professional Pride',
            symbol: {
              type: 'esriSFS',
              color: [158, 85, 156, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Professional Pride',
          },
          {
            label: 'City Lights',
            symbol: {
              type: 'esriSFS',
              color: [252, 146, 31, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'City Lights',
          },
          {
            label: 'Urban Edge Families',
            symbol: {
              type: 'esriSFS',
              color: [255, 222, 62, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Urban Edge Families',
          },
          {
            label: 'Prairie Living',
            symbol: {
              type: 'esriSFS',
              color: [247, 137, 216, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Prairie Living',
          },
          {
            label: 'Set to Impress',
            symbol: {
              type: 'esriSFS',
              color: [183, 129, 74, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Set to Impress',
          },
          {
            label: 'Young and Restless',
            symbol: {
              type: 'esriSFS',
              color: [60, 175, 153, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Young and Restless',
          },
          {
            label: 'Boomburbs',
            symbol: {
              type: 'esriSFS',
              color: [107, 107, 214, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Boomburbs',
          },
          {
            label: 'Emerald City',
            symbol: {
              type: 'esriSFS',
              color: [181, 71, 121, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Emerald City',
          },
          {
            label: 'Urban Villages',
            symbol: {
              type: 'esriSFS',
              color: [127, 127, 127, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Urban Villages',
          },
          {
            label: 'Urban Chic',
            symbol: {
              type: 'esriSFS',
              color: [237, 81, 81, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Urban Chic',
          },
          {
            label: 'Metro Fusion',
            symbol: {
              type: 'esriSFS',
              color: [20, 158, 206, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Metro Fusion',
          },
          {
            label: 'Rural Resort Dwellers',
            symbol: {
              type: 'esriSFS',
              color: [167, 198, 54, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Rural Resort Dwellers',
          },
          {
            label: 'Diverse Convergence',
            symbol: {
              type: 'esriSFS',
              color: [158, 85, 156, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Diverse Convergence',
          },
          {
            label: 'Forging Opportunity',
            symbol: {
              type: 'esriSFS',
              color: [252, 146, 31, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Forging Opportunity',
          },
          {
            label: 'Metro Renters',
            symbol: {
              type: 'esriSFS',
              color: [255, 222, 62, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Metro Renters',
          },
          {
            label: 'Family Foundations',
            symbol: {
              type: 'esriSFS',
              color: [247, 137, 216, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Family Foundations',
          },
          {
            label: 'Down the Road',
            symbol: {
              type: 'esriSFS',
              color: [183, 129, 74, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Down the Road',
          },
          {
            label: 'Golden Years',
            symbol: {
              type: 'esriSFS',
              color: [60, 175, 153, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Golden Years',
          },
          {
            label: 'City Commons',
            symbol: {
              type: 'esriSFS',
              color: [107, 107, 214, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'City Commons',
          },
          {
            label: 'Enterprising Professionals',
            symbol: {
              type: 'esriSFS',
              color: [181, 71, 121, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Enterprising Professionals',
          },
          {
            label: 'Retirement Communities',
            symbol: {
              type: 'esriSFS',
              color: [127, 127, 127, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Retirement Communities',
          },
          {
            label: 'Southwestern Families',
            symbol: {
              type: 'esriSFS',
              color: [237, 81, 81, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Southwestern Families',
          },
          {
            label: 'Trendsetters',
            symbol: {
              type: 'esriSFS',
              color: [20, 158, 206, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Trendsetters',
          },
          {
            label: 'City Strivers',
            symbol: {
              type: 'esriSFS',
              color: [167, 198, 54, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'City Strivers',
          },
          {
            label: 'College Towns',
            symbol: {
              type: 'esriSFS',
              color: [158, 85, 156, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'College Towns',
          },
          {
            label: 'Family Extensions',
            symbol: {
              type: 'esriSFS',
              color: [252, 146, 31, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Family Extensions',
          },
          {
            label: 'Senior Escapes',
            symbol: {
              type: 'esriSFS',
              color: [255, 222, 62, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Senior Escapes',
          },
          {
            label: 'Laptops and Lattes',
            symbol: {
              type: 'esriSFS',
              color: [247, 137, 216, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Laptops and Lattes',
          },
          {
            label: 'NeWest Residents',
            symbol: {
              type: 'esriSFS',
              color: [183, 129, 74, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'NeWest Residents',
          },
          {
            label: 'Economic BedRock',
            symbol: {
              type: 'esriSFS',
              color: [60, 175, 153, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Economic BedRock',
          },
          {
            label: 'Social Security Set',
            symbol: {
              type: 'esriSFS',
              color: [107, 107, 214, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Social Security Set',
          },
          {
            label: 'Pacific Heights',
            symbol: {
              type: 'esriSFS',
              color: [181, 71, 121, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Pacific Heights',
          },
          {
            label: 'Fresh Ambitions',
            symbol: {
              type: 'esriSFS',
              color: [127, 127, 127, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Fresh Ambitions',
          },
          {
            label: 'Unclassified',
            symbol: {
              type: 'esriSFS',
              color: [237, 81, 81, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Unclassified',
          },
          {
            label: 'Silver & Gold',
            symbol: {
              type: 'esriSFS',
              color: [20, 158, 206, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Silver & Gold',
          },
          {
            label: 'Downtown Melting Pot',
            symbol: {
              type: 'esriSFS',
              color: [167, 198, 54, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Downtown Melting Pot',
          },
          {
            label: 'Dorms to Diplomas',
            symbol: {
              type: 'esriSFS',
              color: [158, 85, 156, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Dorms to Diplomas',
          },
          {
            label: 'The Elders',
            symbol: {
              type: 'esriSFS',
              color: [252, 146, 31, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'The Elders',
          },
          {
            label: 'High Rise Renters',
            symbol: {
              type: 'esriSFS',
              color: [255, 222, 62, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'High Rise Renters',
          },
          {
            label: 'Farm to Table',
            symbol: {
              type: 'esriSFS',
              color: [247, 137, 216, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Farm to Table',
          },
          {
            label: 'Military Proximity',
            symbol: {
              type: 'esriSFS',
              color: [183, 129, 74, 255],
              outline: {
                type: 'esriSLS',
                color: [153, 153, 153, 64],
                width: 0.75,
                style: 'esriSLSSolid',
              },
              style: 'esriSFSSolid',
            },
            value: 'Military Proximity',
          },
        ],
      },
    })


        

    view.on("mouse-wheel", (event) => {
      event.stopPropagation();
    });

    view.on("double-click", (event) => {
      event.stopPropagation();
    });

    view.on("drag", (event) => {
      event.stopPropagation();
    });

    // when the map is loaded and ready , then run the function
    view.when(() => {
      // Add the layer to the map
      // view.map.add(worldHillshadeLayer);
      // view.map.add(vectoreTileLayer);

      view.map.add(testLayer)
      // view.map.add(zipCodesLayer);
      // view.map.add(blockLayer);



      
      // view.map.add(worldTopographic);
      // view.map.add(blockLayer);


      setMapLoaded(true);
    });

    
    
    return () => {
      if (view) {
        view.destroy(); // Clean up on unmount
      }


    };
  };
  
  useEffect(() => {
    fn();
  }, []);

  useEffect(() => {
    console.log('map loaded', mapLoaded);
  }, [mapLoaded]);

 

  return <div style={{ height: '100%', width: '100%' }} ref={mapRef}></div>;
};

export default WebMapComponent;
